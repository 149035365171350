import React, {useEffect, useState} from 'react'
import apiService from '../API/apiService'
import { analytics } from '../utils/firebaseUtils'
import Loader from '../components/shared/Loader'

const Analytics = () => {
    const [analytics, setAnalytics] = useState([])
    const [loading , setLoading] = useState(false)
    const [currentPage , setCurrentPage] = useState(1)
    const [totalPages , setTotalPages] = useState(0)

    useEffect(() => {
      const fetchAnalytics = async () => {
              setLoading(true)
        // let token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
        // const options = {
        //   authorization:`Token ${token}`
        // }
        try {
          const response = await apiService.get(`/all-shows-booking-amount/?show_id=${currentPage}`);
          setAnalytics(response.data);
          console.log(response.data)
        //   setCurrentPage(response?.data?.current_page_number)
        //   setTotalPages(response?.data?.total_pages)
                setLoading(false)
        } catch (error) {
          console.error('Error fetching data:', error);
                setLoading(false)
        }
      };
  
      fetchAnalytics();
    }, [currentPage]);
  return (
    <>
    {loading?
    <Loader/>
    :
    <div className='w-full flex flex-col gap-4 p-4'>
      {analytics?.length > 0 ?
      <>
        <p className='font-semibold text-[18px]'>Video sales analytics</p> 

        <div className="grid grid-cols-2 md:grid-cols-6 w-full gap-4">
            {analytics?.map((analytic) => (
            <div className="rounded-md border shadow-md flex flex-col gap-2 p-2">
                <p className='font-semibold text-lg'>{analytic?.show?.title}</p>
                <p className='font-semibold text-[16px]'>KES {analytic?.total_amount}</p>

            </div>
            ))}

        </div>
      </>:
      <div className="">
        <p>No analytics to show at the moment</p>
      </div>
      }
    </div>}
    </>
  )
}

export default Analytics