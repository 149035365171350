import React from 'react'

const AdModalLayout = ({ setToggleModal, ...props }) => {
  return (
    <div
    onClick={() => setToggleModal(false)}
    className='fixed h-screen flex justify-center font-satoshi items-center inset-0 bg-gray-900  bg-opacity-50 dark:bg-opacity-80 z-[999]'>
    <div
        onClick={(e) => e.stopPropagation()}
        className='relative w-full max-w-2xl bg-white rounded-lg p-0 z-50 flex flex-col gap-2 overflow-scroll hide-scrollbar max-h-[90%]'>
        {props.children}
    </div>
</div>
  )
}

export default AdModalLayout