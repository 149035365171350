import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  shows:[],
  ads:[],

  currentPage:1,
  totalPages:0,

}


export const pastShowSlice = createSlice({
    name: 'pastShows',
    initialState,
    reducers:{
        setPastShows: (state, action) => {
            state.shows = action.payload
        },

        setAds: (state, action) => {
            state.ads = action.payload
        },

        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },

        setTotalPages: (state, action) => {
            state.totalPages = action.payload
        },

    }

})

const { setPastShows,setCurrentPage,setAds,
    setTotalPages } = pastShowSlice.actions

const pastShowReducer = pastShowSlice.reducer

export {
    pastShowReducer,     
    setPastShows,
    setCurrentPage,
    setTotalPages,
    setAds,
};