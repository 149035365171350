import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';

// import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { upcomingShowReducer } from "./features/upcoming/upcomingShowSlice";
import { pastShowReducer } from "./features/past/pastShowSlice";

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    stateReconciler: hardSet,
    // blacklist: ['checkout']
}


const rootReducer = combineReducers({
    upcomingShows: upcomingShowReducer,
    pastShows: pastShowReducer,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});

export const persistor = persistStore(store);