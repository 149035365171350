import apiService from '../../API/apiService';
import React, { useEffect, useState } from 'react'
import { BsArrowRightCircleFill } from "react-icons/bs";
import { toast } from 'react-toastify';


const TicketModal = ({ticket, amt,show,ticket_time, ticket_day,setToggleModal}) => {
    const [selectedDay, setSelectedDay] = useState(ticket_day)
    const [selectedTime, setSelectedTime] = useState(ticket_time)
    const [selectedTicket, setSelectedTicket] = useState('')

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [tickets, setTickets] = useState(1)

    const [enableButton, setEnableButton] = useState(false)
    const [processing, setProcessing] = useState(false)


    // Refactor such that user selects day and time to fetch show then if selected time is 3 ask for ticket type


    // checks --- make sure time and day are considers
    // checks --- look into deactivating shows (show should be created for every time eg 3pm show and 6pm show)


    useEffect(() => {
        if(email !== '' && phone !== '' && tickets !== '' && selectedDay !== ''){
            if(selectedDay === 'Friday'){
                setEnableButton(true)
            
            }else if((selectedDay === 'Saturday' || selectedDay === 'Sunday') && selectedTime === 6){
                setEnableButton(true)

            }
            else if((selectedDay === 'Saturday' || selectedDay === 'Sunday') && (selectedTime === 6 || selectedTime === 3)){
                setEnableButton(true)

            }
            else if((selectedDay === 'Saturday' || selectedDay === 'Sunday') && selectedTime === 3 && selectedTicket !== ''){

                setEnableButton(true)
            }
            else{
            setEnableButton(false)

            }

        }else{
            setEnableButton(false)
        }

    },[selectedDay,selectedTime,selectedTicket,email,phone,tickets])


    const handleBuyTicket = async() => {
        setProcessing(true)
        const payload = {
            name,
            email,
            phone:`254${phone}`,
            no_of_tickets:tickets,
            day:selectedDay,
            time_slot:selectedTime,
            // ticket_type:ticket,
            amount:tickets*amt,
            show:show
        }
        if (selectedTicket === 'C'){
            payload.amount=2000

        }

        // console.log('payload',payload)
        try {
        const request = await apiService.post('/tickets/buy/', payload)
        console.log('success',request)
        setProcessing(false)
        toast(request?.data?.success)
                        setTimeout(() => {
        setToggleModal(false)
      }, 2000);
        setToggleModal(false)
        
        } catch(error){
            // console.log('error',error)
            setProcessing(false)
            // setToggleModal(false)
            toast('Something went wrong')
                            setTimeout(() => {
        setToggleModal(false)
      }, 2000);

        }
    }

    const handleChange = (setState) => (event) => {
        setState(event.target.value)
    }



    const handleNumberChange = (event) => {
        // const inputNumber = event.target.value;
        const inputNumber = event.target.value.replace(/^0+/, '');
        setPhone(inputNumber);
        // if (/^\d{0,10}$/.test(inputNumber)) {
        //     setPhone(inputNumber);
        // }
      }; 

  return (
    <div className='font-satoshi w-full h-full flex flex-col gap-1'>
        <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Name on ticket <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <input onChange={handleChange(setName)}
                placeholder='Enter full name to appear on ticket'
                value={name} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Email <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <input onChange={handleChange(setEmail)}
                placeholder='Enter email address to receive tickets'
                value={email} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Phone number <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <div className="flex flex-row border rounded-md">
                    <div className="bg-gray-200 flex items-center p-2">
                        +254
                    </div>
                <input onChange={handleNumberChange}
                placeholder='Enter phone number to receive prompt for payment'
                value={phone} type="number" className='py-2 px-2 rounded-md outline-none w-full' name="" id="" />
                </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>No of tickets  <span className='text-[11px] text-red-500'>( Required *)</span></label>
                
                <input onChange={handleChange(setTickets)}
                placeholder='Enter number of tickets'
                min={1}
                value={tickets} type="number" className='py-2 px-2 border border-gray-200 rounded-md outline-none w-full' name="" id="" />
            </div>
            {/* <div className="grid grid-cols-3 w-full gap-2">
                <div
                onClick={() => {
                    setSelectedDay('Friday')
                    setSelectedTime('')
                    setSelectedTicket('')
                }}
                 className={`${selectedDay === 'Friday'? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} font-semibold hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>Friday</p>
                    <p className='uppercase text-[13px]'>18:30 PM</p>


                </div>
                <div
                onClick={() => {
                    setSelectedDay('Saturday')
                    setSelectedTime('')
                    setSelectedTicket('')
                }} 
                 className={`${selectedDay === 'Saturday'? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} font-semibold hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>saturday</p>
                    <p className='uppercase text-[13px]'>{ticket_time ===3? '15:30 PM':'18:30 PM'}</p>


                </div>
                <div
                onClick={() => {
                    setSelectedDay('Sunday')
                    setSelectedTime('')
                    setSelectedTicket('')
                }}
                 className={`${selectedDay === 'Sunday'? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} font-semibold hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>Sunday</p>
                    <p className='uppercase text-[13px]'>{ticket_time ===3? '15:30 PM':'18:30 PM'}</p>



                </div>

            </div> */}
            {/* {(selectedDay === 'Saturday' || selectedDay === 'Sunday')&&
            <div className="grid grid-cols-2 w-full gap-2">
                {ticket_time ==='3'?
                <div
                onClick={() => setSelectedTime(3)} 
                 className={`${selectedTime === 3 ? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} border hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>{selectedDay}</p>
                    <p className='uppercase text-[13px]'>15:30 PM</p>


                </div>
                :
                <div 
                onClick={() => {setSelectedTime(6)}} 
                className={`${selectedTime === 6 ? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} border hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>{selectedDay}</p>
                    <p className='uppercase text-[13px]'>18:30 PM</p>

                </div>
}

            </div>
            } */}
            {/* {selectedTime === 3  &&(ticket_day === 'Saturday' || ticket_day === 'Sunday') &&
            <div className="grid grid-cols-2 w-full gap-2">
                <div
                onClick={() => {
                    setSelectedTicket('C')
                    setTickets(2)
                }}
                 className={`${selectedTicket === 'C' ? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} border hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>Couple's Ticket</p>
                    <p className='uppercase text-[13px]'>2 @ Ksh, 2000</p>


                </div>
                <div
                onClick={() => {
                    setSelectedTicket('S')
                    setTickets(1)

                }}
                 className={`${selectedTicket === 'S' ? 'border text-[#39D2C0]  border-[#39D2C0]':'border'} border hover:border-[#39D2C0] hover:bg-teal-100 hover:text-[#39D2C0] cursor-pointer rounded-md shadow-sm px-3 py-1 col-span-1 flex flex-col gap-1 justify-center items-center`}>
                    <p className='uppercase text-[13px]'>Single Ticket</p>

                </div>

            </div>
            } */}
            <div className="flex flex-row-reverse">
                <button
                onClick={handleBuyTicket}
                 className={` ${enableButton ? 'bg-[#39D2C0] text-white':'bg-gray-200 pointer-events-none'} flex flex-row justify-between items-center  gap-4 rounded-md px-4 py-1.5 `}>{processing ? "Requesting payment...":"Pay now"}
                <BsArrowRightCircleFill/>
                </button>
            </div>
        </div>
    </div>
  )
}

export default TicketModal