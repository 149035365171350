import React, { useEffect, useState } from 'react'
import UpcomingShow from '../components/dashboard/UpcomingShow'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { IoAddOutline } from "react-icons/io5";
import AddShowFormModal from '../components/modals/AddShowFormModal';
import ModalLayout from '../components/modals/ModalLayout';
import SellTicket from '../components/modals/SellTicket';
import apiService from '../API/apiService';
import Loader from '../components/shared/Loader';
import Paginator from '../components/shared/Paginator';
import AddGalleryModal from '../components/modals/AddGalleryModal';
import { HiChevronDown } from "react-icons/hi";
import { BiCalendarEvent } from "react-icons/bi";
import { toast } from 'react-toastify';
import { ADMIN_SEAT, ENDPOINT_SEAT } from '../routes';


const Dashboard = () => {
  const [addShowModal, setAddShowModal] = useState(false)
  const [sellTicketModal, setSellTicketModal] = useState(false)
  const [upcomingShows, setUpcomingShows] = useState([])
  const [loading , setLoading] = useState(false)
  const [currentPage , setCurrentPage] = useState(1)
  const [totalPages , setTotalPages] = useState(0)
  const [previous , setPrevious] = useState(0)
  const [next , setNext] = useState(0)


  let currentUrlParams = new URLSearchParams(window.location.search)
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
  const [openTicketModal, setOpenTicketModal] = useState('')
  const [show, setShow] = useState('')
  const [search, setSearch] = useState('')
  const [showId, setShowId] = useState('')


  const [amount, setAmount] = useState('')
  const [ticketTime, setTicketTime] = useState('')
  const [showTime, setShowTime] = useState('')
  const [showDay, setShowDay] = useState('')
  const [showDays, setShowDays] = useState(false)
  const [showTimeSlots, setShowTimeSlots] = useState(false)
  const [processing, setProcessing] = useState(false)
  const navigate = useNavigate()



  const seatMap = () => {
      // currentUrlParams.set('day', showDay.toUpperCase())
      // currentUrlParams.set('time', showTime)
      // setUrlParams(currentUrlParams)
      if(showTime !=='' && showDay !==''){
          navigate(`${ADMIN_SEAT}/?day=${showDay.toUpperCase()}&time=${showTime}`)
      }else{

          toast.error('Select day and time')
      }
  }


  useEffect(() => {
      const fetchShows = async () => {
          setProcessing(true)
        try {
          const response = await apiService.get(`/upcoming-show/?day=${showDay.toUpperCase()}&time=${showTime}`);
          setShow(response.data);

          setProcessing(false)

        } catch (error) {
          console.error('Error fetching data:', error);
          setProcessing(false)

        }
      };
  
      fetchShows();
    }, [showTime,showDay]);

  // useEffect(() => {
  //     if (show !== '' || show !== null || show !== undefined){

  //         setShowTime(show.time)
  //         setShowDay(show.day)
  //     }

  // },[])

    // console.log('show',show)
    // console.log('showTime',showTime)
    // console.log('showDay',showDay)

  
  const handleCloseTicketModal = () => {
      setOpenTicketModal('')
      }
  const handleTicketClick = (ticket,amt) => {
      if(showTime !=='' && showDay !==''){

          setOpenTicketModal(ticket)
          setAmount(amt)
      }else{
          toast.error("Select the day and time slot you want to book a ticket for!");
      }
  }    

  const handleSelectDay = (day) => {
      setShowDay(day)
      setShowDays(false)
  }

  const handleSelectTimeSlot = (slot) => {
      setShowTime(slot)
      setShowTimeSlots(false)
  }
  useEffect(() => {
      if(showDay === 'Friday'){

          setShowTime(6)
      }
  },[showDay])




  const token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
  useEffect(() => {
    const fetchUpcomingShows = async () => {
        const options = {
          authorization:`Token ${token}`
        }
      setLoading(true)
      try {
        const response = await apiService.get(`/upcoming-shows-no-tickets`, {headers:options});
        setUpcomingShows(response.data);

      setLoading(false)

      } catch (error) {
        console.error('Error fetching data:', error);
      setLoading(false)

      }
    };

    fetchUpcomingShows();
  }, [addShowModal,sellTicketModal]);


  const [ticketSales, setTicketSales] = useState([])
  const [totalSales, setTotalSales] = useState(0)


//   function sumAmounts(items) {
//     let total = 0;
//     for (let i = 0; i < items.length; i++) {
//         total += parseFloat(items[i].amount);
//     }
//     return total;
// }
  useEffect(() => {
    const fetchTicketSales = async () => {
        const options = {
          authorization:`Token ${token}`
        }
      try {
        const response = await apiService.get(`/ticket-bookings/?page=${currentPage}&q=${search}&show=${showId}`, {headers:options});
        setTicketSales(response.data);
        console.log(response.data)
        setCurrentPage(response?.data?.current_page_number)
        setTotalPages(response?.data?.total_pages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTicketSales();
  }, [currentPage,search,showId]);
  useEffect(() => {
    const fetchTicketSalesReports = async () => {
        const options = {
          authorization:`Token ${token}`
        }
      try {
        const response = await apiService.get(`/dashboard/reports`, {headers:options});
        setTotalSales(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchTicketSalesReports();
  }, []);
  
  function addCommasToNumber(number) {
    // Convert number to string
    let numStr = number?.toString();
    
    // Split the number into integer and decimal parts
    let parts = numStr?.split('.');
    
    // Add commas to the integer part
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    // Join the integer and decimal parts with '.' and return
    return parts.join('.');
}
  console.log('ticketSales',ticketSales)
  console.log('upcomingShows',upcomingShows)

  const [addGalleryModal, setAddGalleryModal] = useState(false)
  const handleCloseGalleryModal = () => {
    setAddGalleryModal(false)
  }


  const handleCloseModal = () => {
    setAddShowModal(false)
  }
  const handleCloseSellModal = () => {
    setSellTicketModal(false)
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);


  const handleSelectChange = (event) => {
    setShowId(event.target.value);
  };
  return (
    <>
    {loading?
    <Loader/>
    :
    <div className='px-4 flex font-satoshi flex-col w-full gap-4'>
      <div className="flex flex-row justify-between w-full">

      <p className='text-[14px] md:text-[20px] font-semibold'>Upcoming Shows</p>
      <div className="flex flex-row gap-2">
      <button onClick={() => setAddShowModal(true)} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        <IoAddOutline size={20}/>
      <p className='text-[12px] md:text-[16px]'>Add new show</p>
        </div>
      </button>
      <button onClick={() => setAddGalleryModal(true)} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        <IoAddOutline size={20}/>
      <p className='text-[12px] md:text-[16px]'>Add gallery</p>
        </div>
      </button>
      </div>
      </div>
      {upcomingShows.length > 0 ?
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-4 w-full">
        {upcomingShows?.map((show) => (

        <div className="col-span-1">
          
          <UpcomingShow
          image={show?.image_url}
          id={show?.id}
          day={show?.day}
          time={show?.time}
          name={show?.name}
          tickets={show?.tickets}
          venue={show?.venue}
          />
        </div>
        
        ))}
      </div>
      :
      <div className="flex flex-row justify-center items-center w-full">
        <p className='text-[14px] md:text-[18px]'>No any upcoming shows at the moment</p>
      </div>
    }
      
      <div className="flex flex-row justify-between w-full">

      <div className="">
      <p className='font-semibold text-[14px] md:text-[18px]'>Ticket sales</p>  
      </div>

      
                      <div className="flex flex-row gap-4">
                      {/* <p className='text-amber-700 capitalize'>{show?.day}</p> */}
                      <BiCalendarEvent className='text-gray-400' size={30} />
                      <button onClick={() => setShowDays(!showDays)} className='flex flex-row justify-between gap-2 px-2 py-1 items-center bg-gray-600 rounded-md  relative'>{showDay !== ''?showDay:'Select Day'}
                          <HiChevronDown size={20}/>
                          {showDays &&
                          <div className="absolute -bottom-32 right-1 w-full flex flex-col gap-2 bg-gray-600 rounded-md py-1">
                              <p onClick={() => handleSelectDay('Friday')} className='hover:bg-white hover:text-black p-1 cursor-pointer rounded-sm bg-gray-100 text-black'>Friday</p>
                              <p onClick={() => handleSelectDay('Saturday')} className='hover:bg-white hover:text-black p-1 cursor-pointer rounded-sm bg-gray-100 text-black'>Saturday</p>
                              <p onClick={() => handleSelectDay('Sunday')} className='hover:bg-white hover:text-black p-1 cursor-pointer rounded-sm bg-gray-100 text-black'>Sunday</p>
  
                          </div>
                              }
                          </button>
                      {/* <p className='text-amber-700 capitalize'>July 9, 2022</p> */}
                      <div className="">
                          <button onClick={() => setShowTimeSlots(!showTimeSlots)} className='flex flex-row justify-between gap-2 px-2 py-1 items-center bg-gray-600 rounded-md  relative'>{showTime === 3 ? '15:00 PM': showTime === 6 ? '18:30 PM':'Select time '}
                          <HiChevronDown size={20}/>
                          {showTimeSlots && showDay !== 'Friday' &&
                          <div className="absolute -bottom-20  right-1 w-full flex flex-col gap-2 bg-gray-600 rounded-md px-2 py-1">
                              <p onClick={() => handleSelectTimeSlot(3)} className='hover:bg-white hover:text-black p-1 cursor-pointer rounded-sm bg-gray-100 text-black'>15:00 PM</p>
                              <p onClick={() => handleSelectTimeSlot(6)} className='hover:bg-white hover:text-black p-1 cursor-pointer rounded-sm bg-gray-100 text-black'>18:30 PM</p>
  
                          </div>
                              }
                          </button>
                      </div>
                      <div className="">
                        <button onClick={seatMap} className='bg-gray-200 rounded-md px-4 py-2 text-[12px]'>View</button>
                      </div>
                  </div>

      
    </div>
    <div className="flex flex-col">
    <p className=' text-[14px] md:text-[18px]'>Total amount: KES {totalSales?.total_amount}</p>
    <p className=' text-[14px] md:text-[18px]'>Total tikcets sold: {totalSales?.total_tickets}</p>

    </div>
    <div className="flex flex-col md:flex-row w-full items-center gap-2">

    <div className="flex flex-row w-full border rounded-md p-2 bg-white">
        <input 
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Search by name, phone, email or day...'
        type="text" className='outline-none flex-1 w-[94%]' />
      </div>
        <select 
        onChange={handleSelectChange}
        name="show" id="" className='p-2 outline-none'>
          {upcomingShows?.map((show) => (
          <option  value={show?.id}>{show?.name}-{show?.day}-{show?.time}</option>
          ))}
        </select>
    </div>
    {ticketSales?.results?.length >0?
    <>
      <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                        {!isMobile &&
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Email</th>

                          }
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Name</th>

                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Phone number</th>
                          {/* {!isMobile && */}
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Show</th>
                          {/* // } */}
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Day</th>
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Time</th>
                          {/* <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Ticket</th> */}
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Seat(s)</th>
                          <th className='pl-2 pb-1 font-satoshi text-[10px] md:text-[16px] tracking-wide text-left text-gray-600'>Amount</th>

                        </tr>
                      </thead>
                      <tbody>
                        {ticketSales?.results?.map((sale) => (

                            <tr className='p-2 border-b border-b-gray-200 relative'>
                              {!isMobile &&
                              <td className='pl-2 py-2'>
                              <p className='text-[10px] md:text-[14px] '>{sale?.email}</p>

                              </td>
                              }
                               <td className='pl-2 py-2'>
                                  <p className='text-[10px] md:text-[14px] '>{sale?.name}</p>
                                
                              </td>
                              <td className='pl-2 py-2'>
                                  <p className='text-[10px] md:text-[14px] '>{sale?.phone_number !== "sale by admin" && "+"}{sale?.phone_number}</p>
                                
                              </td>
                              {!isMobile &&
                              <td className='pl-2 py-2'>
                                <p className='text-[10px] md:text-[14px]'>{sale?.show.name}</p>
                              </td>
                              }
                              <td className='pl-2 py-2'>
                              <p className='text-[10px] md:text-[14px]'>{sale?.day}</p>

                              </td>
                              <td className='pl-2 py-2'>
                              <p className='text-[10px] md:text-[14px]'>{parseInt(sale?.time_slot) ===3? "15:00 PM":"18:30 PM"}</p>
 
                              </td>
                              {/* <td className='pl-2 py-2'>
                              <p className='text-[10px] md:text-[14px]'>{sale?.ticket_type}</p>
 
                              </td> */}
                              <td className='pl-2 py-2'>
                              <div className='text-[10px] flex flex-col md:flex-row md:text-[13px] flex-wrap'>
                                {sale?.seats?.map((seat)=> (
                                <span className='m-2'>'{seat}',</span>
                              ))}
                              </div>
 
                              </td>
                              <td className='pl-2 py-2'>
                              <p className='text-[10px] md:text-[14px]'>{sale?.amount}</p>
 
                              </td>
                            </tr>
                        ))}



                      </tbody>
                    </table>
      </div>
      
      <Paginator currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
    </>

      :
      <div className="flex flex-row justify-center items-center w-full">
        <p className='text-[18px]'>No any ticket sales at the moment</p>
      </div>
    }
    </div>
  }
    {addShowModal &&
    <ModalLayout open={addShowModal} setToggleModal={handleCloseModal} title={`Add show`}>
      <AddShowFormModal setToggleModal={handleCloseModal}/>
    </ModalLayout>
    }
    {sellTicketModal &&
    <ModalLayout open={sellTicketModal} setToggleModal={handleCloseSellModal} title={`Sell ticket`}>
      <SellTicket setToggleModal={handleCloseSellModal}/>
    </ModalLayout>
    }

{addGalleryModal &&
    <ModalLayout open={addGalleryModal} setToggleModal={handleCloseGalleryModal} title={`Add gallery`}>
      <AddGalleryModal setToggleModal={handleCloseGalleryModal}/>
    </ModalLayout>
    }
    </>
  )
}

export default Dashboard