import React, { useRef, useState, useEffect} from 'react'
import Card from './Card'
import osoro from '../assets/images/osoro.png'
import kwach from '../assets/images/kwach.png'
import victor from '../assets/images/nyaata.png'
import ndenga from '../assets/images/ndenga.png'
import betty from '../assets/images/manager.jpeg'
import { motion, useScroll, useTransform  } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const Team = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

    const target = useRef()
    const [ref, inView] = useInView({
        triggerOnce: true,
      });
    const { scrollYProgress } = useScroll({
      target:target,
      offset: ["start end", "end start"]
    });
  
    const opacity = useTransform(scrollYProgress, [0,0.5],[0,1])
    const scale = useTransform(scrollYProgress, [0,0.5],[0.1,1])

    const team = [
        {name:'Dennis Ndenga', image:ndenga, ig:'https://instagram.com/dennisndenga?igshid=MzRlODBiNWFlZA==', description:"Director cronyproductions ", sub:"Creative the trend ntv"},
        {name:'Nick Kwach', image:kwach, ig:'https://instagram.com/nickkwach?igshid=MzRlODBiNWFlZA==', description:"Producer cronyproductions ", sub:"Co host the trend ntv"},
        {name:'Cyprian Osoro', image:osoro, ig:'https://instagram.com/osorosoro?igshid=MzRlODBiNWFlZA==', description:"Assistant Director cronyproductions", sub:"Creative the trend ntv"},
        {name:'Victor Nyaata', image:victor, ig:'https://instagram.com/victornyaata?igshid=MzRlODBiNWFlZA==', description:"Director cronyproductions ", sub:"Creative the trend ntv"},
        {name:'Betty Nyatuga', image:betty, ig:'https://instagram.com/victornyaata?igshid=MzRlODBiNWFlZA==', description:""},

    ]
  return (
    <motion.div    id='team' className='w-full h-full font-satoshi '>
        <div  className="container mx-auto px-2 py-10 flex flex-col gap-2 w-full h-full">
            <div className="flex flex-col gap-1">
            <p className=' text-[60px] font-semibold text-teal-400'>About us</p>
            <motion.p
                ref={ref}
                initial={{ x: -1000 }}
                animate={{ x: inView ? 0 : -100 }}

            transition={{
                duration: "2",
                delay:"1"
            }}
             className=' text-[16px] text-white'>CronyProductions is a dynamic theater group comprising four passionate individuals dedicated to bringing captivating performances to the stage. With a shared love for the art of theater, they seamlessly blend creativity and talent to produce memorable plays.

             Each member contributes a unique skill set, from acting to directing and set design.</motion.p>


            </div>
            <p className=' text-[30px] uppercase font-semibold text-amber-600'>Meet the team</p>
            <motion.div ref={target} style={{opacity, scale}}  
            // className="animate-in slide-in-from-left delay-7 grid grid-cols-1 md:grid-cols-4 px-4 md:px-0 gap-2 w-full h-full"
            className={` ${isMobile ? '' : 'auto-cols-min'} grid grid-flow-col hide-scrollbar  overflow-x-scroll w-full gap-4 py-6 `}
            >
                {team?.map((member) => (

                <div className="col-span-1 w-[18rem] h-full">
                    <Card
                    name={member?.name}
                    image={member?.image}
                    fb={member?.fb}
                    ig={member?.ig}
                    desc={member?.description}
                    sub={member?.sub}
                    
                    />
                </div>
                ))}
            </motion.div>
        </div>
    </motion.div>
  )
}

export default Team