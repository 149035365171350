import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import apiService from '../../API/apiService'
import { toast } from 'react-toastify';


function SellTicket({setToggleModal}) {
    const [ticketType, setTicketType] = useState('')
    const [numberOfTickets, setNumberOfTickets] = useState('')
    const [day, setDay] = useState('')
    const [time, setTime] = useState('')
    const [ticket, setTicket] = useState('')

    const [show, setShow] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [amount, setAmount] = useState('')
    const [shows, setShows] = useState([])
    const [processing, setProcessing] = useState(false)



    

    useEffect(() => {
        const fetchShowsMin = async () => {
          try {
            const response = await apiService.get(`/shows-minimal`);
            setShows(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchShowsMin();
      }, []);



    const handleSellShowTicket = async (selectedFile) => {
        setProcessing(true)
        try {
          const payload = {
            no_of_tickets:parseInt(numberOfTickets),
            ticket_type:ticketType.value,
            time:time.value,
            day:day.value,
            amount:amount,
            show_id:show.value,
            email:email,
            phone:`254${phone}`
    
          }
        //   console.log('payload', payload)
          const request = await apiService.post('/manual-show-sell/', payload)
          setProcessing(false)
          toast(request?.data?.success)
                          setTimeout(() => {
        setToggleModal(false)
      }, 2000);
          setTicketType('')
          setNumberOfTickets('')
          setDay('')
          setTime('')
          setShow('')
          setEmail('')
          setPhone('')
          setAmount('')
        } catch (error) {
          toast('Error occured during upload')
                          setTimeout(() => {
        setToggleModal(false)
      }, 2000);
          setProcessing(false)
          setTicketType('')
          setNumberOfTickets('')
          setDay('')
          setTime('')
          setShow('')
          setEmail('')
          setPhone('')
          setAmount('')
        }
      };



    const showDay = [
    { value: 'FRIDAY', label: 'Friday' },
    { value: 'SATURDAY', label: 'Saturday' },
    { value: 'SUNDAY', label: 'Sunday' }
    ]

    const showTime = [
        { value: 3, label: '15:30 PM' },
        { value: 6, label: '18:30 PM' }
        ]

    const showTicketType = [
        { value: 'VVIP', label: 'VVIP' },
        { value: 'VIP', label: 'VIP' },
        { value: 'REGULAR', label: 'REGULAR' }
        ]
    

    const handleChange = (setState) => (event) => {
                setState(event.target.value)
            }

    const handleChangeSelect = (setState) => (value) => {
        setState(value);
        };
  return (
    <div className='flex flex-col w-full h-full gap-2'>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Email <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <input onChange={handleChange(setEmail)}
                placeholder='Enter email address'
                value={email} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Phone number <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <div className="flex flex-row border rounded-md">
                    <div className="bg-gray-200 flex items-center p-2">
                        +254
                    </div>
                <input onChange={handleChange(setPhone)}
                placeholder='Enter phone number'
                value={phone} type="number" className='py-2 px-2 rounded-md outline-none w-full' name="" id="" />
                </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Amount <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <input onChange={handleChange(setAmount)}
                placeholder='Enter amount'
                value={amount} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
            </div>
    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Show</label>
        <Select value={show} options={shows}  onChange={handleChangeSelect(setShow)}/>
    </div>
    {/* <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Ticket</label>
        <Select value={ticketType} options={showTicketType}  onChange={handleChangeSelect(setTicketType)}/>
    </div> */}
    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Day</label>
        <Select value={day} options={showDay}  onChange={handleChangeSelect(setDay)}/>
    </div>
    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Time</label>
        <Select value={time} options={showTime}  onChange={handleChangeSelect(setTime)}/>
    </div>
    <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Number of tickets</label>
        <input value={numberOfTickets} onChange={(e) =>setNumberOfTickets(e.target.value)} type="number" min={1} className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>

    <div className="flex flex-row-reverse">
        <button onClick={handleSellShowTicket} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{processing ? 'Processing...':'Submit'}</button>
        
    </div>
</div>
  )
}

export default SellTicket