import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BiCurrentLocation } from "react-icons/bi";
import { LuAlarmClock } from "react-icons/lu";
import { BsCalendar2Check } from "react-icons/bs";
import ModalLayout from '../modals/ModalLayout';
import AddShowTicket from '../modals/AddShowTicket';
import { IoAddOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ConvertToPastShow from '../modals/ConvertToPastShow';
import apiService from '../../API/apiService';
import { FaDeleteLeft } from 'react-icons/fa6'
import { toast } from 'react-toastify';

const UpcomingShow = ({image, id, day, time, name,venue,tickets}) => {
    const [addShowTicketModal, setAddShowTicketModal] = useState(false)
    const [convertModal, setConvertModal] = useState(false)


console.log('time',time)

    const handleCloseModal = () => {
      setAddShowTicketModal(false)
    }
    const handleCloseConvertModal = () => {
        setConvertModal(false)
      }


    const handleDeleteShow= async () => {
      try {
        
        const request = await apiService.put(`/delete-show/${id}/`)
        
        toast(request?.data?.success)
      } catch (error) {
        toast('Error occured during upload')

      }
    };
    console.log('tickets',tickets)
    console.log('id',id)

  return (
    <>
    <Link className='w-full h-full bg-white flex flex-col gap-2 rounded-md shadow-sm p-2 relative'>
      <div onClick={handleDeleteShow} className="absolute top-0 right-0 cursor-pointer">
        <FaDeleteLeft className='text-red-500' size={30}/>
      </div>
        <img 
        className='h-44 object-cover'
        src={image} alt="" />
        <p className='font-semibold text-[18px]'>{name}</p>

        <div className="flex flex-row gap-2">
        <div className="flex flex-row gap-1 items-center">
            <BsCalendar2Check size={20}/>
            <p>{day}</p>
         </div>
        
         <div className="flex flex-row gap-1 items-center">
            <LuAlarmClock size={20}/>
            <p>{time} PM</p>
         </div>
            
            

        </div>
        <div className="flex flex-row gap-1 items-center">
            <BiCurrentLocation size={25}/>
        <p className='uppercase'>{venue.name}</p>

        </div>
        <div className="flex flex-col md:flex-row gap-2 items-start md:items-center justify-start md:justify-between">

        {/* <button onClick={() => setAddShowTicketModal(true)} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        <IoAddOutline size={20}/>
      <p className='text-xs md:text-[14px]'>Add tickets</p>
        </div>
      </button> */}
      {/* <div className="bg-gray-200 rounded-full px-4 py-1 flex flex-row relative">
        <p className='text-[12px]'>REGULAR</p>
        <div className="absolute right-0 -top-2">
        <AiOutlineCloseCircle className='text-red-600'/>
        </div>
      </div> */}
      {/* <div className="bg-gray-200 rounded-full px-4 py-1 flex flex-row relative">
        <p className='text-[12px]'>VIP</p>
        <div className="absolute right-0 -top-2">
        <AiOutlineCloseCircle className='text-red-600'/>
        </div>
      </div> */}
      {/* <div className="bg-gray-200 rounded-full px-4 py-1 flex flex-row relative">
        <p className='text-[12px]'>VVIP</p>
        <div className="absolute right-0 -top-2">
        <AiOutlineCloseCircle className='text-red-600'/>
        </div>
      </div> */}

      <button onClick={() => setConvertModal(true)} className='bg-gray-800 text-white text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        
      <p className='text-xs md:text-[14px]'>Sell recording</p>
        </div>
      </button>
        </div>

    </Link>
    {addShowTicketModal &&
    <ModalLayout open={addShowTicketModal} setToggleModal={handleCloseModal} title={`Add show ticket`}>
      <AddShowTicket id={id} venue={venue} setToggleModal={handleCloseModal} activeShow={tickets}/>
    </ModalLayout>
    }
    {convertModal &&
    <ModalLayout open={convertModal} setToggleModal={handleCloseConvertModal} title={`Close show and add it to previous shows`}>
      <ConvertToPastShow id={id} setToggleModal={handleCloseConvertModal}/>
    </ModalLayout>
    }
    </>
  )
}

export default UpcomingShow