import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const Loader = () => {
  return (
    <div
    className='fixed h-screen flex justify-center font-satoshi items-center inset-0 bg-gray-900  bg-opacity-50 dark:bg-opacity-80 z-[999]'>
    <div
        className='relative w-full max-w-2xl text-white rounded-lg py-4 px-8 z-50 flex flex-col gap-2 overflow-scroll hide-scrollbar max-h-[90%]'>
        <div className="flex flex-col justify-center items-center">
            <ColorRing
            visible={true}
            height="100"
            width="100"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />

        <p className='text-[20px]'>Loading...</p>
        </div>
    </div>
</div>
  )
}

export default Loader