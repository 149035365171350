import React, { useState } from 'react'
import { IoAddOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { handleUploadFilesAsync } from '../../utils/actions';
import apiService from '../../API/apiService';


const AddGalleryModal = ({setToggleModal}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewURLs, setPreviewURLs] = useState([]);
    const [description, setDescription] = useState([]);
    const [buttonText, setButtonText] = useState('Submit')

    const handleCoverChange = (event) =>  {
        setSelectedFile(event.target.files[0]);
        const uploadedImage = event.target.files[0];
        setPreviewURL(URL.createObjectURL(uploadedImage));
    
        }
    const handleFileChange = (event) => {
          const files = event.target.files;
          const selectedFilesArray = Array.from(files);
          setSelectedFiles(selectedFilesArray);
      
          const urls = selectedFilesArray.map((file) => URL.createObjectURL(file));
          setPreviewURLs(urls);
        };    
    
    const handleDeleteImage = () => {
        setSelectedFile(null)
        setPreviewURL('')
    }  

    console.log('selectedFiles',selectedFiles)

    const handleUploadFilessAsync = async (files) => {
    console.log('files',files)
      const uploadPromises = files?.map(async (file) => {
        try {
          return await handleUploadFilesAsync(file);
        } catch (error) {
          console.error("Error uploading file:", error);
          return null;
        }
      });
    
      const downloadURLs = await Promise.all(uploadPromises);
      console.log('downloadURLs',downloadURLs)
      return downloadURLs;
    };

    const token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
    const handleCreateGallery = async () => {
        setButtonText('Uploading image...')
        if (selectedFiles.length === 0) {
          return;
        }
        try {
          const downloadImages = await handleUploadFilessAsync(selectedFiles);
          if (downloadImages) {
            // sendDataToAPI(downloadURL);
            // console.log('downloadURL',downloadURL);
            setButtonText('Saving...')
    
    
          } else {
            // console.error('Error uploading file');
            setButtonText('Save')
            return
    
          }
          
          const images = downloadImages?.map((item) => ({
            image_url: item
          }));
          const payload = {
            title:description.toUpperCase(),
            image_url:downloadImages[0],
            images:images,
            owner:'CR'
    
          }
          console.log('payload', payload)
          const request = await apiService.post('/gallery/', payload
          )
          setButtonText('Save')
          toast(request?.data?.success)
                          setTimeout(() => {
            setToggleModal(false)
          }, 2000);
          setDescription('')
            setSelectedFiles([])
            setPreviewURLs([])
        } catch (error) {
          console.error('Error handling file upload:', error);
          toast('An error occured.')
                          setTimeout(() => {
            setToggleModal(false)
          }, 2000);
          setDescription('')
          setButtonText('Save')
          setSelectedFiles([])
          setPreviewURLs([])
    
        }
      };

      const handleDelete = (index) => {
        const newSelectedFiles = [...selectedFiles];
        const newPreviewURLs = [...previewURLs];
    
        newSelectedFiles.splice(index, 1);
        newPreviewURLs.splice(index, 1);
    
        setSelectedFiles(newSelectedFiles);
        setPreviewURLs(newPreviewURLs);
      };
  return (
    <>
        <div className='flex flex-col w-full h-full gap-2'>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Add gallery folder name</label>
        <input  value={description} onChange={(e) => setDescription(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Image
              </label>
              <div className="flex flex-row gap-1 items-center">
              <div className="mt-2 w-20 h-20 flex  items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
                    >
                      <span>
                        <IoAddOutline size={40}/>
                      </span>
                      <input
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                       id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                  </div>
                </div>
              </div>
              {selectedFiles && previewURLs &&(
                <div className="flex flex-row gap-2 w-full overflow-x-scroll hide-scrollbar">
                  {previewURLs.map((url, index) => (

                <div key={index} className="w-20 h-20 relative">
                    <img src={url} className='w-full h-full object-cover rounded border' alt="" />
                    <div onClick={() =>handleDelete(index)} className="absolute cursor-pointer right-0 top-0">
        <AiOutlineCloseCircle size={25} className='text-red-600'/>
        </div>
                </div>
                  ))}
                </div>
            )}
              </div>
            </div>
        <div className="flex flex-row-reverse">
            <button onClick={handleCreateGallery} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{buttonText}</button>
        </div>
        </div>
    </>
  )
}

export default AddGalleryModal