import React, { useEffect, useState } from 'react'
import Loader from '../components/shared/Loader'
import apiService from '../API/apiService'
import Paginator from '../components/shared/Paginator'
import ModalLayout from '../components/modals/ModalLayout'
import AddAdvertModal from '../components/modals/AddAdvertModal'
import { IoAddOutline } from "react-icons/io5";
import { FaDeleteLeft } from 'react-icons/fa6'
import { toast } from 'react-toastify'

const Adverts = () => {
    const [addShowModal, setAddShowModal] = useState(false)
    const [ticketSales, setTicketSales] = useState([])
    const [loading , setLoading] = useState(false)
    const [currentPage , setCurrentPage] = useState(1)
    const [totalPages , setTotalPages] = useState(0)

    const fetchTicketSales = async () => {
            setLoading(true)
      // let token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
      // const options = {
      //   authorization:`Token ${token}`
      // }
      try {
        const response = await apiService.get(`/admin-view/adverts/?page_size=20&page=${currentPage}`, 
          // {headers:options}
      );
      console.log(response.data)
        setTicketSales(response.data);
        setCurrentPage(response?.data?.current_page_number)
        setTotalPages(response?.data?.total_pages)
              setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
              setLoading(false)
      }
    };
    useEffect(() => {
  
      fetchTicketSales();
    }, [currentPage]);

    const handleCloseModal = () => {
        setAddShowModal(false)
      }
      const handleDeleteShow= async (id) => {
        try {
          
          const request = await apiService.put(`/delete-advert-admin/${id}/`)
          
          toast(request?.data?.success)
          fetchTicketSales()
        } catch (error) {
          toast('Error occured during upload')
  
        }
      };
  return (
    <>
    {loading?
    <Loader/>
    :

    <div  className='px-4 flex font-satoshi flex-col w-full gap-4'>
        <div className="flex flex-row w-full justify-between items-center gap-2">

        <p className='font-semibold text-[18px]'>Adverts</p> 
        <button onClick={() => setAddShowModal(true)} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        <IoAddOutline size={20}/>
      <p className='text-[12px] md:text-[16px]'>Add new advert</p>
        </div>
      </button>
        </div>
        
        {ticketSales?.results?.length>0 ?
        <>
<div className="">
<div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-4 w-full">
        {ticketSales?.results?.map((sale) => (

        <div className="col-span-1 border p-2 rounded-md shadow-sm relative">
            <div onClick={() => handleDeleteShow(sale?.id)} className="absolute top-0 right-0 cursor-pointer">
        <FaDeleteLeft className='text-red-500' size={30}/>
      </div>
            <div key={sale.id} className="w-full">
                <div className="w-full h-[40]">
                <img src={sale?.banner_url} className='w-full h-full' alt="" />
                </div>
                <div className="flex flex-col w-full gap-2">
                    <p>{sale?.title}</p>
                    <p>{sale?.contact}</p>
                    <a href={sale?.url} target='_blank' className='hover:text-blue-500'>{sale?.url}</a>
                    <a href={sale?.link} target='_blank' className='hover:text-blue-500'>{sale?.link}</a>

                </div>
            </div>
        </div>
        
        ))}
      </div>
</div>
    <Paginator currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage}/>

        </>
    :
      <div className="flex flex-row justify-center items-center">
        <p className='text-[18px]'>No any ads running at the moment</p>
      </div>
    }
    </div>
    }
        {addShowModal &&
    <ModalLayout open={addShowModal} setToggleModal={handleCloseModal} title={`Add advert`}>
      <AddAdvertModal setToggleModal={handleCloseModal} fetchData={fetchTicketSales}/>
    </ModalLayout>
    }
    </>
  )
}

export default Adverts