import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import apiService from '../../API/apiService'
import { toast } from 'react-toastify';



const AddShowTicket = ({id, activeShow,venue,setToggleModal}) => {
    const [ticketType, setTicketType] = useState('')
    const [price, setPrice] = useState('')

    const [seatRows, setSeatRows] = useState([])
    const [venueRows, setVenueRows] = useState([])
    const [processing, setProcessing] = useState(false)
    const [selectedseatRows, setSelectedSeatRows] = useState([])
    const [selectedTickets, setSelectedTickets] = useState([])




    useEffect(() => {
      const fetchSeatRows = async () => {
        try {
          const response = await apiService.get(`/seat-rows/?venue=${venue}`);
          setVenueRows(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchSeatRows();
    }, []);

    // console.log('seatRows',seatRows)
    console.log('activeShow',activeShow)

    useEffect(() => {
      activeShow.map((ticke) => {
        ticke.seat_rows.map((se)=> {
          console.log('se',ticke.ticket_type)
          let tickes = selectedTickets
          if (!tickes.includes(ticke.ticket_type)){

            tickes.push(ticke.ticket_type)
            setSelectedTickets(tickes)
          }
          
          let array = selectedseatRows
          if (!array.includes(se)){

            array.push(se)
            setSelectedSeatRows(array)
          }
          
    })
  })
    },[])

console.log('selectedseatRows',selectedseatRows)
console.log('selectedTickets',selectedTickets)

    const handleChangeSelect = (setState) => (value) => {
        setState(value);
      };
    const handleSelect = (id) => {
        if(!seatRows.includes(parseInt(id))){
            setSeatRows([...seatRows, parseInt(id)])
        }else{
            const newArray = seatRows.filter((order) => order !== (parseInt(id)))
            setSeatRows(newArray)
        }

    }

    // console.log('seatRows',seatRows)


const token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
    const handleCreateShowTicket = async (selectedFile) => {
        setProcessing(true)
        try {
          const payload = {
            price:price,
            show_id:id,
            ticket_type:ticketType.value,
            seat_rows:seatRows
    
          }
          const options = {
          authorization:`Token ${token}`
          }
          console.log('payload', payload)
          const request = await apiService.post('/add-ticket/', payload)
          setProcessing(false)
          setTicketType('')
          setPrice('')
          toast(request?.data?.success)
                setTimeout(() => {
        setToggleModal(false)
      }, 2000);
        } catch (error) {
          toast('Error occured during upload')
                setTimeout(() => {
        setToggleModal(false)
      }, 2000);
          setProcessing(false)
          setTicketType('')
          setPrice('')

        }
      };

    const [showTicketType, setShowTicketType] = useState([
    { value: 'VVIP', label: 'VVIP' },
    { value: 'VIP', label: 'VIP' },
    { value: 'REGULAR', label: 'REGULAR' }
    ])

    useEffect(() => {
      // Update showTicketType on component load or when selectedTickets changes
      const filteredTicketTypes = showTicketType.filter(
        (ticketType) => !selectedTickets.includes(ticketType.value)
      );
      setShowTicketType(filteredTicketTypes);
    }, [selectedTickets]);

  return (
    <div className='flex flex-col w-full h-full gap-2'>

    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Ticket</label>
        <Select value={ticketType} options={showTicketType}  onChange={handleChangeSelect(setTicketType)}/>
    </div>
    <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Price</label>
        <input value={price} type="number" min={0} onChange={(e) => setPrice(e.target.value)} className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Seat rows</label>
            <div className="flex flex-col gap-1 w-full">
            {venueRows?.map((seatRow) => (

            <div className="flex flex-row gap-1 items-center">
              {!selectedseatRows.includes(seatRow?.value) &&
              <>
                <input checked={seatRows.includes(seatRow?.value)} onChange={(e) =>handleSelect(e.target.value)} value={seatRow?.value} type="checkbox" className={` accent-slate-800 w-5 h-5 rounded-md outline-none`} />
                <p>{seatRow?.label}</p>
              </>}
            </div>
            ))}
            
            </div>
        </div>
    <div className="flex flex-row-reverse">
        <div className="flex flex-row gap-4">
        {/* <button className='px-4 py-1.5 text-center rounded-md bg-gray-300'>Save and add another</button> */}

        <button onClick={handleCreateShowTicket} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{processing? 'Saving...':"Save"}</button>
        </div>
    </div>
</div>
  )
}

export default AddShowTicket