import { useState } from "react";
import { storage } from './firebaseUtils';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


const handleUploadFiles = (file) => {

    if (!file) return;

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    
    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        // setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          return downloadURL
        });
      }
    );
  }


  export const handleUploadFilesAsync = async (file) => {
    if (!file) return null;
  
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // console.log('storageRef',storageRef)
    // console.log('uploadTask',uploadTask)
    // console.log('{file.name}',file.name)

  
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // setProgresspercent(progress);
        },
        (error) => {
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };  


  const uploadFileToFirebaseStorage = (file) => {
    if (!file) {
      return Promise.resolve(null);
    }

    const storageRef = ref(storage, `/products/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        reject,
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(resolve)
            .catch(reject);
        }
      );
    });
  };

  
  export const isAuthenticated = () => {
    const token = localStorage.getItem('LS_ACCESS_TOKEN')
    if (token) {
        // check if token is valid from backend
        return true
    }
    return false
}  