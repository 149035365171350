import React from 'react'
import { useLocation } from 'react-router-dom'

const Seat = ({seatNo, booked, handleClick, occupied,aisle,space_side,handleClickSelectFroUnsel}) => {
  const { pathname } = useLocation()

  return (
    <div onClick={occupied && pathname.includes('dashboard')? () => handleClickSelectFroUnsel(seatNo) : () => handleClick(seatNo)} className={`h-4 md:h-7 w-3 md:w-6 py-2 px-3 ${booked ? 'bg-teal-500': 'bg-gray-600 text-white hover:text-black hover:scale-105'} ${occupied && pathname.includes('dashboard') && 'bg-white cursor-pointer'} ${occupied && !pathname.includes('dashboard') && 'bg-white pointer-events-none'} ${aisle && 'bg-teal-500'} ${aisle && occupied &&  pathname.includes('dashboard') && 'bg-slate-50 cursor-pointer'} ${aisle && occupied && !pathname.includes('dashboard') &&'bg-slate-50 pointer-events-none'} ${aisle && !occupied && !booked && 'bg-yellow-800'} flex items-center justify-center  rounded-t-2xl cursor-pointer ${space_side === 'T' && ""} ${space_side === 'L' ? 'ml-5 md:ml-10': space_side ==='R'?'mr-5 md:mr-10':''}`}>
        
        <p className='text-[8px] md:text-[12px] font-satoshi font-semibold'>
        {seatNo}
        </p>
    </div>
  )
}

export default Seat