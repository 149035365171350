import React, { useState } from 'react'
import { Outlet } from "react-router-dom";
import LoginModal from './components/modals/LoginModal';
import { isAuthenticated } from './utils/actions';

export const ENDPOINT_HOME         = '/'
export const ENDPOINT_SEAT         = '/seat-map'

export const ENDPOINT_WATCH_SHOW   = '/watch-show/:Id'
export const ENDPOINT_DASHBOARD    = '/dashboard'
export const ENDPOINT_LINK_SALES   = `${ENDPOINT_DASHBOARD}/link-sales`
export const ENDPOINT_PAST_SHOWS   = `${ENDPOINT_DASHBOARD}/past-shows`

export const ENDPOINT_ANALYTICS   = `${ENDPOINT_DASHBOARD}/analytics`
export const ENDPOINT_ADVERTS     = `${ENDPOINT_DASHBOARD}/adverts`
export const ENDPOINT_SETTINGS    = `${ENDPOINT_DASHBOARD}/settings`



export const ADMIN_SEAT            = `${ENDPOINT_DASHBOARD}/map`



export const AuthenticatedRoute = () => {
    const [login, setLogin] = useState(true)
    return (
        isAuthenticated() ? <Outlet /> : <LoginModal show={login} setOnBoarding={setLogin} />
    )
}



