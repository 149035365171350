import React from 'react'
import logo from '../assets/images/clogobg.png'
import { Link } from 'react-router-dom'
import { ENDPOINT_HOME } from '../routes'


const Stage = () => {
  return (
    <div className="flex flex-row gap-4 w-full">
      <Link to={ENDPOINT_HOME} className="">
              <img src={logo} alt="" className='h-20' />
                {/* <p className='text-white'>THE CRONY</p> */}
      </Link>
      <div className='w-full flex-1 bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] fle flex-row justify-center items-center font-satoshi trapezoid shadow-md drop-shadow-lg'>
      </div>
      <div className=""></div>
    </div>
  )
}

export default Stage