import React from 'react'


const FirstPastShow = ({image}) => {
  return (
    <>
    <div className="h-40 ">


    </div>
    </>
  )
}

export default FirstPastShow