import React, { useState } from 'react'

import { toast } from 'react-toastify';
import apiService from '../../API/apiService';
import { FaDeleteLeft } from 'react-icons/fa6'

const PastShow = ({image, id, price,name}) => {
    const [editing, setEditing] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [showPrice, setShowPrice] = useState(price)

    const handleEditShowPrice = async () => {
        setProcessing(true)
        try {
          const payload = {
            price:parseInt(showPrice),
    
          }
          console.log('payload', payload)
          const request = await apiService.put(`past-shows/${id}/`, payload)
          setProcessing(false)
          setEditing(false)
          toast(request?.data?.success)
        } catch (error) {
          toast('Error occured during upload')
          setProcessing(false)
          setEditing(false)

        }
      };
      
      const handleDeleteShow= async () => {
      try {
        
        const request = await apiService.put(`delete-past-show/${id}/`)
        
        toast(request?.data?.success)
      } catch (error) {
        toast('Error occured during upload')

      }
    };



  return (
    <>
        <div className='w-full h-full bg-white flex flex-col gap-2 rounded-md shadow-sm p-2 relative'>
             <div onClick={handleDeleteShow} className="absolute top-0 right-0 cursor-pointer">
        <FaDeleteLeft className='text-red-500' size={30}/>
      </div>
        <img 
        className='h-44 object-cover'
        src={image} alt="" />
        <p className='font-semibold text-[18px]'>{name}</p>
        <div className={`flex ${editing ? 'flex-col':'flex-row '} gap-2 justify-between`}>
            {/* Change to input to enable editing */}
            <div className="flex flex-row items-center">
              {!editing ? 
            <p className='text-[14px]'>Ksh. {showPrice}</p>
                  :
        <input type='number' value={showPrice} onChange={(e) => setShowPrice(e.target.value)} readOnly={!editing} className={`${editing ? 'px-2 py-1 outline-none border rounded-md':''} text-[14px] px-2 py-2`}/>}
            </div>
        <div className="flex flex-row  gap-1">

        <button onClick={() => setEditing(!editing)} className='bg-gray-200 text-center px-4 py-1 rounded-md'>{editing? 'Cancel':'Edit'}</button>
        {editing &&
        <button onClick={handleEditShowPrice}  className='bg-gray-800 text-white text-center px-4 py-1 rounded-md'>{processing? 'Saving...':'Submit'}</button>
        }

        </div>
        </div>


    </div>
    </>
  )
}

export default PastShow