import React from 'react'
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { HiOutlineMailOpen } from "react-icons/hi";
import logo from '../../assets/images/clogobg.png'

import { BsFillPhoneVibrateFill } from "react-icons/bs";
import {BiMailSend} from 'react-icons/bi'


const Footer = () => {
  return (
    <div className='w-full font-satoshi text-white h-full px-3 py-10 shadow-inner  '>
         <div className="container mx-auto px-4 py-2 test-white flex flex-col">
          <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
          <div className="flex h-[8rem] w-[8rem] md:h-[16rem] md:w-[16rem] -mt-20">
            <img src={logo} className='h-full w-full object-cover' alt="" />
          </div>
          <div className="flex flex-col w-full">
          <p className='text-[18px]'>Mobile apps coming soon</p>

          <div className="flex">
        <div
          href=""
          class="transition duration-200 ease-in hover:box-shadow hover:opacity-80"
        >
          <img
            src="https://www.logo.wine/a/logo/App_Store_(iOS)/App_Store_(iOS)-Badge-Logo.wine.svg"
            alt="App Store"
            className="w-36 lg:w-44 xl:w-auto h-28"

          />
        </div>

        <div
          href=""
          class="transition duration-200 ease-in hover:box-shadow hover:opacity-80"
        >
          <img
            src="https://www.logo.wine/a/logo/Google_Play/Google_Play-Badge-Logo.wine.svg"
            alt="App Store"
            className="w-36 lg:w-44 xl:w-auto h-28"

          />
        </div>
          </div>

          </div>
          <div className="flex flex-col gap-2 w-full">
            <p className=''>Subscribe to our newsletter to get updates on upcoming shows and events.</p>
            <input type="text" className='outline-none px-2 py-2 rounded-sm w-full bg-gray-300 text-gray-900' name="" id="" />
            <div className="flex">
              
            <button className='text-center bg-gray-500 px-6 py-2 rounded-sm'>Submit</button>
            </div>
          </div>
          </div>
        <div className={` flex flex-col md:flex-row gap-2 justify-start md:justify-center py-2 border-b border-gray-600 items-start md:items-center`}>
            <div className="flex flex-row gap-2 justify-center items-center">
              <BsFillPhoneVibrateFill className='text-white' size={20}/>
            <p className='text-white text-[14px] md:text-[16px] border-r-2 border-gray-600 pr-3'>0113 346 363</p>
            <p className='text-white text-[14px] md:text-[16px]'>0113 409 225</p>

            </div>

            <a href='mailto:thecronyproduction@gmail.com' className="flex flex-row gap-2 justify-center items-center">
              <BiMailSend className='text-white' size={20}/>
            <p className='text-white text-[14px] md:text-[16px]'>thecronyproduction@gmail.com</p>
            </a>

          </div>
            <div className="flex flex-col md:flex-row-reverse py-2 justify-between">
                <div className="flex flex-row gap-4 items-center">
                    <a href='https://www.facebook.com/profile.php?id=100076884653562&mibextid=b06tZ0' target='_blank' className="cursor-pointer">
                       <FaFacebookSquare size={30} className='text-[#3b5998]'/>
                    </a>
                    <a href='https://instagram.com/thecronyproductions?igshid=MzRlODBiNWFlZA==' target='_blank' className="cursor-pointer">
                        <FaInstagram size={30} className='ig'/>
                    </a>
                    <a href='mailto:thecronyproduction@gmail.com' className="cursor-pointer">
                        <HiOutlineMailOpen size={30}/>
                    </a>
                </div>
                <div className="">
                &copy; {new Date().getFullYear()} The crony productions. All rights reserved. Designed and developed by <a target='_blank' href="https://www.apexlivingsoftwarelabs.co.ke/" className='text-teal-400'>Apex living software labs</a>
                </div>
                <div className=""></div>
            </div>

        </div> 
    </div>
  )
}

export default Footer