
import apiService from '../../API/apiService';
import React, { useEffect, useState } from 'react'
import { BsArrowRightCircleFill } from "react-icons/bs";
import { toast } from 'react-toastify';

const MapBuyTicket = ({setToggleModal, selected_seats,amt, selectedDay,selectedTime, show}) => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [processing, setProcessing] = useState(false)
    const handleBuyTicket = async() => {
        setProcessing(true)
        const payload = {
            email,
            name,
            phone:`254${phone}`,
            // no_of_tickets:tickets,
            day:selectedDay,
            time_slot:selectedTime,
            // ticket_type:ticket,
            amount:selected_seats.length *amt,
            show:show,
            selected_seats:selected_seats
        }
        // if (selectedTicket === 'C'){
        //     payload.amount=1500

        // }

        console.log('payload',payload)
        try {
        const request = await apiService.post('/tickets/map/buy/', payload)
        setProcessing(false)
        toast(request?.data?.success)
                        setTimeout(() => {
        setToggleModal(false)
      }, 2000);
        setToggleModal(false)
        
        } catch(error){
            // console.log('error',error)
            setProcessing(false)
            // setToggleModal(false)
            toast('Something went wrong')
                            setTimeout(() => {
        setToggleModal(false)
      }, 2000);

        }
    }
    const handleNumberChange = (event) => {
        // const inputNumber = event.target.value;
        const inputNumber = event.target.value.replace(/^0+/, '');
        setPhone(inputNumber);
        // if (/^\d{0,10}$/.test(inputNumber)) {
        //     setPhone(inputNumber);
        // }
      }; 
    // const handleNumberChange = (event) => {
    //     const inputNumber = event.target.value;
    //     if (/^\d{0,9}$/.test(inputNumber)) {
    //         setPhone(inputNumber);
    //     }
    //   }; 

    const handleChange = (setState) => (event) => {
        setState(event.target.value)
    }
  return (
    <div className='font-satoshi w-full h-full flex flex-col gap-1'>
    <div className="flex flex-col gap-2">
    <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[16px]'>Name on ticket <span className='text-[11px] text-red-500'>( Required *)</span></label>
                <input onChange={handleChange(setName)}
                placeholder='Enter full name to appear on ticket'
                value={name} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
            </div>
        <div className="flex flex-col gap-1 w-full">
            <label htmlFor="" className='text-[16px]'>Email <span className='text-[11px] text-red-500'>( Required *)</span></label>
            <input onChange={handleChange(setEmail)}
            placeholder='Enter email address to receive tickets'
            value={email} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
        </div>
        <div className="flex flex-col gap-1 w-full">
            <label htmlFor="" className='text-[16px]'>Phone number <span className='text-[11px] text-red-500'>( Required *)</span></label>
            <div className="flex flex-row border rounded-md">
                <div className="bg-gray-200 flex items-center p-2">
                    +254
                </div>
            <input onChange={handleNumberChange}
            placeholder='Enter phone number to receive prompt for payment'
            value={phone} maxLength={9} type="number" className='py-2 px-2 rounded-md outline-none w-full' name="" id="" />
            </div>
        </div>

        <div className="flex flex-row-reverse">
            <button
            onClick={handleBuyTicket}
             className={`${email.length>0 && phone.length > 0 ? 'bg-[#39D2C0]':'bg-gray-200 pointer-events-none'}  text-white flex flex-row justify-between items-center  gap-4 rounded-md px-4 py-1.5 `}>{processing ? "Requesting payment...":"Pay now"}
            <BsArrowRightCircleFill/>
            </button>
        </div>
    </div>
</div>
  )
}

export default MapBuyTicket