import React, { useEffect, useState, useRef } from 'react'
import Show from './Show'
import apiService from '../API/apiService';
import { useDispatch } from 'react-redux';
import { setAds, setPastShows } from '../app/features/past/pastShowSlice';
import { motion, useScroll, useTransform } from "framer-motion";
import { HiArrowLongDown } from "react-icons/hi2"
import ImageSlider from './ImageSlider';
import placeholderAd from '../assets/images/images.jpeg'


const Shows = () => {

  const target = useRef()
  const { scrollYProgress } = useScroll({
    target:target,
    offset: ["start end", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0,0.5],[0,1])
  const scale = useTransform(scrollYProgress, [0,0.5],[0.1,1])
  const opacityMobile = useTransform(scrollYProgress, [0,0.5],[0.6,1])
  const scaleMobile = useTransform(scrollYProgress, [0,0.5],[0.8,1])

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);

    const [previousShows, setPreviousShows] = useState([])
    const [ads, setAds] = useState([])

        const dispatch = useDispatch()  

        const fetchShows = async () => {
          try {
            const response = await apiService.get(`/past-shows`);
            setPreviousShows(response.data);
            dispatch(setPastShows(response.data.results))
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        const fetchAds = async () => {
          try {
            const response = await apiService.get(`/main-view/adverts/`);
            setAds(response.data);
            console.log('ads',response)
            dispatch(setAds(response.data))
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    useEffect(() => {
  
      fetchShows();
      fetchAds();
    }, []);

    // console.log('previousShows',previousShows)
 


  return (
    <div  id='shows' className="w-full   font-satoshi relative px-2">

    <div className="container mx-auto flex flex-col gap-2  ">
      <div className="flex flex-row w-full justify-between items-center gap-2">
        <div className="flex flex-col flex-1">
        <p className='font-bold text-[36px] md:text-[60px] px-2 text-teal-400'>Previous shows</p>
        <p className=' text-[16px] text-white'>Buy link of your favourite previous shows or shows that you missed..<br></br> Remember link once purchased can only be used from a single device.please open the link from the device you wish to watch from.</p>
        </div>
        <div className="w-[30%] md:w-[20%]">
          {ads.length > 0 ?
          <ImageSlider ads={ads}/>
          :
          <div className="flex-none w-full">
          <div className="h-60 w-full object-contain rounded-md shadow-md">
            <img
              src={placeholderAd}
              alt={`Image`}
              className="w-full h-full aspect-square rounded"
            />
          </div>
        </div>
          }
        </div>

      </div>
        <div className="flex flex-col w-full h-full py-10 px-4">
        <div style={{
          opacity: isMobile ? opacityMobile : opacity,
          scale: isMobile ? scaleMobile : scale,

      }} 
       className={` ${isMobile ? '' : 'auto-cols-min'} grid grid-flow-col custom-scrollbar overflow-x-scroll w-full gap-4 py-6 `}>
      {previousShows?.results?.map((prevShow) => (
          <div className="w-[18rem]">


            <Show id={prevShow?.id} 
            image={prevShow?.banner_url}
             video_url={prevShow?.url}
             title={prevShow?.title}
             price={prevShow?.price}

             />
          </div>
      ))}
    </div>
    {/* <video width="750" height="500" controls >
      <source src={`gs://cron-14640.appspot.com/changePin.mp4`} type="video/mp4"/>
     </video> */}

        </div>


    </div>
    <a href='#team' className="child animate-bounce  flex flex-row justify-center items-center bg-amber-700 rounded-full p-4">
            {/* <div className="bg-white"> */}
                <HiArrowLongDown size={25}/>
            {/* </div> */}
        </a>
    </div>
  )
}

export default Shows