import React, { useState } from 'react'
import kwatch from '../assets/images/kwach.png'
import ModalLayout from './modals/ModalLayout'
import BuyLinkModal from './modals/BuyLinkModal'

const Show = ({id,image,video_url,title,price}) => {
    const [showBuyModal, setShowBuyModal] = useState('')
    const[amt, setAmt] = useState(price)

    const handleCloseBuyLinkModal = () => {
        setShowBuyModal('');
    }
    
  return (
    <>
    <div className='w-full h-full bg-[#171415] font-satoshi text-gray-400 rounded-md shadow-md'>
        <div className="flex flex-col w-full h-full gap-2">
            <div className="flex felx-col w-full h-full">

            <img src={image} alt="" className='w-full h-full rounded' />
            </div>
            <div className="flex flex-col gap-1 w-full h-full px-3 py-3">
                {/* name */}
                <p className='font-semibold text-[16px] uppercase'>{title}</p>
                {/* date */}
                <div className="flex flex-row gap-1">
                    {/* calendar Icon */}
                    <p className='text-[14px] text-gray-400'>Ksh,{price}</p>
                </div>
                <div className="flex flex-row w-full">
                    <button
                    onClick={() => setShowBuyModal(id)}
                     className='text-[14px] font-semibold text-center flex items-center justify-center text-gray-400 rounded-full bg-gray-700 px-6 py-1'>Buy Link</button>
                </div>
            </div>
        </div>
    </div>

    {showBuyModal !== '' &&
    <ModalLayout open={showBuyModal !== ''} setToggleModal={handleCloseBuyLinkModal} title={`Purchase link`}>
        <BuyLinkModal  show={showBuyModal} setToggleModal={handleCloseBuyLinkModal} amount={amt} />
    </ModalLayout>
    }
    </>
  )
}

export default Show