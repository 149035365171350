import React, {useState} from 'react'
import apiService from '../../API/apiService'
import { handleUploadFilesAsync } from '../../utils/actions'
import { toast } from 'react-toastify'
import { IoAddOutline } from "react-icons/io5";

import { BsCameraVideo } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";


// past_show_data = {
//   'title': show.name,
//   'banner_url': show.image_url,
//   'url': url,
//   'price': price,
// }
// 
const AddPastShow = ({setToggleModal}) => {
    const [price, setPrice] = useState('')
    const [name, setName] = useState('')
    const [bannerUrl, setBannerUrl] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [buttonText, setButtonText] = useState('Save')
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');
    const [processing, setProcessing] = useState(false);

    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [previewImageURL, setPreviewImageURL] = useState('');

    const handleCoverChange = (event) =>  {
        console.log('invoked')

        setSelectedImageFile(event.target.files[0]);
        const uploadedImage = event.target.files[0];
        setPreviewImageURL(URL.createObjectURL(uploadedImage));
    
        }
    
    const handleDeleteImage = () => {
        setSelectedImageFile(null)
        setPreviewImageURL('')
    } 



    const handleFileChange = (event) => {
        console.log('called')
        const file = event.target.files[0];
        setSelectedFile(file);
        setPreviewURL(URL.createObjectURL(file));
      };
    
    const handleDeleteVideo = () => {
        setSelectedFile(null)
        setPreviewURL('')
    }  

    console.log('selectedFile',selectedFile)
    console.log('previewURL',previewURL)

    console.log('selectedImageFile',selectedImageFile)
    console.log('previewImageURL',previewImageURL)

    // its view should first deactivate the current show before creating a past instance

    // show_id
    // url
    // price
const token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
    const handleCreatePastShow = async () => {
        setProcessing(true)
        setButtonText('Uploading files...')
                  const options = {
          authorization:`Token ${token}`
        }
        try {
          const imagedownloadURL = await handleUploadFilesAsync(selectedImageFile);

          const downloadURL = await handleUploadFilesAsync(selectedFile);
          if (downloadURL) {
            // sendDataToAPI(downloadURL);
            // console.error('downloadURL',downloadURL);
            setProcessing(false)
            setButtonText('Saving...')
    
    
          } else {
            console.error('Error uploading video');
            setProcessing(false)
            setButtonText('Save')
    
          }
          if (imagedownloadURL) {
            // sendDataToAPI(downloadURL);
            // console.error('downloadURL',downloadURL);
            setProcessing(false)
            setButtonText('Saving...')
    
    
          } else {
            console.error('Error uploading image');
            setProcessing(false)
            setButtonText('Save')
    
          }
          const payload = {
            title:name,
            price:price,
            url:downloadURL,
            banner_url:imagedownloadURL,
            owner:'CR'
    
          }
          console.log('payload', payload)
          const request = await apiService.post('/add-past-show-manual/',  payload)
          setProcessing(false)
          setButtonText('Save')
          toast(request?.data?.success)
                          setTimeout(() => {
        setToggleModal(false)
      }, 2000);
            setPrice('')
            setSelectedFile(null)
            setPreviewURL('')
        } catch (error) {
        //   console.error('Error handling file upload:', error);
          toast('Error occured during upload')
                          setTimeout(() => {
        setToggleModal(false)
      }, 2000);
          setProcessing(false)
          setButtonText('Save')
          setPrice('')
          setSelectedFile(null)
          setPreviewURL('')
    
        }
      };

  return (
    <div className='flex flex-col w-full h-full gap-2'>

    <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Price</label>
        <input value={price} type="number" onChange={(e) => setPrice(e.target.value)} min={0} className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Name</label>
            <input value={name} type="text" onChange={(e) => setName(e.target.value)}  className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        
        </div>
        <div className="flex flex-col gap-1">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Cover Image
              </label>
              <div className="flex flex-row gap-1 items-center">
              <div className="mt-2 w-20 h-20 flex  items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
                    >
                      <span>
                        <IoAddOutline size={40}/>
                      </span>
                      <input
                      accept="image/*"
                      onChange={handleCoverChange}
                       id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                  </div>
                </div>
              </div>
              {selectedImageFile && (
                <div className="w-20 h-20 relative">
                    <img src={previewImageURL} className='w-full h-full object-cover rounded' alt="" />
                    <div onClick={handleDeleteImage} className="absolute cursor-pointer right-0 -top-2">
        <AiOutlineCloseCircle size={30} className='text-red-600'/>
        </div>
                </div>
            )}
              </div>
            </div>
        {selectedFile && (
        <div className="flex flex-col py-2 border border-gray-200 p-2">
        
            <div className="w-full relative">

                <video
                  src={previewURL}
                  controls
                  className="w-full mb-4 relative"
                />
                <div onClick={handleDeleteVideo} className="absolute cursor-pointer right-0 -top-2">
        <AiOutlineCloseCircle size={30} className='text-red-600'/>
        </div>
            </div>
     
        </div>
         )}
         
        <div className="flex flex-col gap-1">
        <div className="col-span-full">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Video
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="video-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
                    >
                      <span>
                        <BsCameraVideo size={40}/>
                      </span>
                      <input
                      accept="video/*"
                      onChange={handleFileChange}
                       id="video-upload" name="video-upload" type="file" className="sr-only" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </div>
    <div className="flex flex-row-reverse">
        <button onClick={handleCreatePastShow} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{buttonText}</button>

    </div>
</div>
  )
}

export default AddPastShow