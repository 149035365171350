// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyBDgoM_bs8aZvsWRduj02glJdIH28850ds",
//   authDomain: "ndnga-8ad3f.firebaseapp.com",
//   projectId: "ndnga-8ad3f",
//   storageBucket: "ndnga-8ad3f.appspot.com",
//   messagingSenderId: "934533807963",
//   appId: "1:934533807963:web:e11b99bbcdd6204f2c647e",
//   measurementId: "G-4KE8LFDT57"
// };


const firebaseConfig = {
  apiKey: "AIzaSyC0S_kaQiTZXrlngI1yHSYVB8eBiLzEpl0",
  authDomain: "cronyprod.firebaseapp.com",
  projectId: "cronyprod",
  storageBucket: "cronyprod.appspot.com",
  messagingSenderId: "863319292861",
  appId: "1:863319292861:web:1c835e26bd94e03bf430d4",
  measurementId: "G-MLN7H926N8"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const storage = getStorage(app);

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);

