import React from 'react'
import { BiChevronsLeft, BiChevronLeft, BiChevronsRight, BiChevronRight } from "react-icons/bi";


const Paginator = ({currentPage, totalPages, setCurrentPage}) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      };
    
      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
        }
      };
  return (
    <div className='flex flex-row justify-center items-center gap-4 font-satoshi'>
        <div onClick={() => setCurrentPage(1)} className="cursor-pointer hover:text-blue-600">
            <BiChevronLeft/>
        </div>
        <div onClick={handlePrevPage} className="cursor-pointer hover:text-blue-600">
            <BiChevronsLeft/>
        </div>

        <div className="flex flex-row gap-1 justify-center items-center">
            <p className='text-[14px]'>{currentPage}</p>
            <p className='text-[14px]'>of</p>
            <p className='text-[14px]'>{totalPages}</p>

        </div>
        <div onClick={handleNextPage} className="cursor-pointer hover:text-blue-600">
            <BiChevronsRight/>
        </div>

        <div onClick={() => setCurrentPage(totalPages)} className="cursor-pointer hover:text-blue-600">
            <BiChevronRight/>
        </div>

    </div>
  )
}

export default Paginator