import React, { useEffect, useState,useRef } from 'react'
import PastShow from '../components/dashboard/PastShow'
import apiService from '../API/apiService';
import Loader from '../components/shared/Loader';
import Paginator from '../components/shared/Paginator';
import { IoAddOutline } from "react-icons/io5";

import { useDispatch } from 'react-redux';
import { setPastShows } from '../app/features/past/pastShowSlice';
import AddPastShow from '../components/modals/AddPastShow';
import ModalLayout from '../components/modals/ModalLayout';
import { motion, useScroll, useTransform } from "framer-motion";

const PastShows = () => {
  const scrollRef = useRef(null)

    const [shows, setShows] = useState([])
      const [addShowModal, setAddShowModal] = useState(false)
        const [currentPage , setCurrentPage] = useState(1)
        const [loading , setLoading] = useState(false)
        const [totalPages , setTotalPages] = useState(0)


    useEffect(() => {
      const fetchPastShows = async () => {
        setLoading(true)
        let token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
        const options = {
          authorization:`Token ${token}`
        }
        try {
          const response = await apiService.get(`/past-shows/?page_size=15&page=${currentPage}`, {headers:options});
          setShows(response.data);
          setCurrentPage(response?.data?.current_page_number)
          setLoading(false)
          setTotalPages(response?.data?.total_pages)
        } catch (error) {
          console.error('Error fetching data:', error);
            setLoading(false)
        }
      };
  
      fetchPastShows();
    }, [currentPage]);

    const handleCloseModal = () => {
      setAddShowModal(false)
    }
  return (
    <>
    {loading?
    <Loader/>
    :
    <div   className='px-4 flex font-satoshi flex-col w-full gap-4'>
      <div className="flex flex-row justify-between">

        <p className='text-[20px] font-semibold'>Past Shows</p>
        <button onClick={() => setAddShowModal(true)} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
      <div className="flex flex-row gap-2 items-center">
        <IoAddOutline size={20}/>
      <p className='text-[12px] md:text-[16px]'>Add  show</p>
        </div>
      </button>
      </div>
    {shows?.results?.length>0 ?
        <>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 w-full">
            {shows?.results?.map((show) => (

        <div className="col-span-1">
          <PastShow
          image={show?.banner_url}
          id={show?.id}
          price={show?.price}
          name={show?.name}
          />
        </div>
            ))}

      </div>
      <Paginator currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
        </>
      :
      <div className="flex flex-row justify-center items-center">
        <p className='text-[18px]'>No any past shows at the moment</p>
      </div>
    }
    </div>
    }

{addShowModal &&
    <ModalLayout open={addShowModal} setToggleModal={handleCloseModal} title={`Add show`}>

      <AddPastShow setToggleModal={handleCloseModal}/>
    </ModalLayout>
    }
    </>
  )
}

export default PastShows