import React, { useEffect, useState } from 'react'
import apiService from '../../API/apiService'
import { handleUploadFilesAsync } from '../../utils/actions'
import { toast } from 'react-toastify';
import { IoAddOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AddAdvertModal = ({setToggleModal, fetchData}) => {
    const [title, setTitle] = useState('')
    const [contact, setContact] = useState('')
    const [url, setUrl] = useState('')
    const [link, setLink] = useState('')
    const [banner_url, setBannerUrl] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');
    const [processing, setProcessing] = useState(false);
    const [buttonText, setButtonText] = useState('Save')





    const handleCoverChange = (event) =>  {
        setSelectedFile(event.target.files[0]);
        const uploadedImage = event.target.files[0];
        setPreviewURL(URL.createObjectURL(uploadedImage));
    
        }
    
    const handleDeleteVideo = () => {
        setSelectedFile(null)
        setPreviewURL('')
    }  

    const handleChangeSelect = (setState) => (value) => {
        setState(value);
      };

      const handleCreateShow = async () => {
        setProcessing(true)
        setButtonText('Uploading image...')
        try {
          const downloadURL = await handleUploadFilesAsync(selectedFile);
          if (downloadURL) {
            // sendDataToAPI(downloadURL);
            // console.log('downloadURL',downloadURL);
            setProcessing(false)
            setButtonText('Saving...')
    
    
          } else {
            // console.error('Error uploading file');
            setProcessing(false)
            setButtonText('Save')
    
          }
          const payload = {
            title,
            contact,
            url,
            link,
            banner_url:downloadURL
    
          }
        //   const options = {
        //       authorization:`Token ${token}`
        //     }
          console.log('payload', payload)
          const request = await apiService.post('/admin-create/advert/', payload)
          setProcessing(false)
          toast(request?.data?.success)
                          setTimeout(() => {
            setToggleModal(false)
          }, 2000);
            setTitle("")  
            setContact("")
            setUrl("")
            setLink("")
            setBannerUrl("")
            setSelectedFile(null)
            setPreviewURL('')
          setButtonText('Save')
          fetchData()
    
        } catch (error) {
          console.error('Error handling file upload:', error);
          toast('Error occured during upload')
                          setTimeout(() => {
            setToggleModal(false)
          }, 2000);
          setProcessing(false)
          setButtonText('Save')
          setTitle("")  
          setContact("")
          setUrl("")
          setLink("")
          setBannerUrl("")
          setSelectedFile(null)
          setPreviewURL('')
    
        }
      };
  return (
    <div className='flex flex-col w-full h-full gap-2'>
    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Title</label>
    <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
    </div>

    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Contact</label>
    <input value={contact} onChange={(e) => setContact(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
    </div>

    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Link</label>
    <input value={link} onChange={(e) => setLink(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
    </div>
    <div className="flex flex-col gap-1">
        <label htmlFor="" className='text-[14px]'>Additional URL</label>
    <input value={url} onChange={(e) => setUrl(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
    </div>
    
    <div className="flex flex-col gap-1">
          <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
            Cover photo
          </label>
          <div className="flex flex-row gap-1 items-center">
          <div className="mt-2 w-20 h-20 flex  items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
            <div className="text-center">
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
                >
                  <span>
                    <IoAddOutline size={40}/>
                  </span>
                  <input
                  accept="image/*"
                  onChange={handleCoverChange}
                   id="file-upload" name="file-upload" type="file" className="sr-only" />
                </label>
              </div>
            </div>
          </div>
          {selectedFile && (
            <div className="w-20 h-20 relative">
                <img src={previewURL} className='w-full h-full object-cover rounded' alt="" />
                <div onClick={handleDeleteVideo} className="absolute cursor-pointer right-0 -top-2">
    <AiOutlineCloseCircle size={30} className='text-red-600'/>
    </div>
            </div>
        )}
          </div>
        </div>

    
    <div className="flex flex-row-reverse">
        <button onClick={handleCreateShow} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{buttonText}</button>
    </div>
</div>
  )
}

export default AddAdvertModal