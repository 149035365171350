import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/clogobg.png'
import { ENDPOINT_ADVERTS, ENDPOINT_ANALYTICS, ENDPOINT_DASHBOARD, ENDPOINT_HOME, ENDPOINT_LINK_SALES, ENDPOINT_PAST_SHOWS, ENDPOINT_SETTINGS } from '../../routes';
import { BsCardText,BsArrowRightSquare,BsCalendar2Event } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import { BiSolidBookmarks,BiSlideshow } from "react-icons/bi"
import { FiMenu,FiX } from "react-icons/fi";
import { IoMdAnalytics } from "react-icons/io";
import { RiAdvertisementLine } from "react-icons/ri";
import { IoSettings } from "react-icons/io5";


function TopBar() {
    const { pathname } = useLocation()
    const [openSidebar, setOpenSidebar] = useState(false)
  return (
    <div className='w-full py-2 bg-white shadow-sm font-satoshi'>
        <div className="flex flex-row justify-between px-2 items-center">
        <Link to={ENDPOINT_HOME}>
            <img src={logo} alt="logo" className='h-16 object-cover' />
            </Link>
            <div onClick={() => setOpenSidebar(!openSidebar)} className="cursor-pointer items-center">
                {openSidebar? 
                <FiX size={30}/>
                : 
                <FiMenu size={30}/>
                }
            </div>
        </div>
        {openSidebar &&
                    <div className="flex flex-col gap-3 w-full px-3">
                    <Link to={ENDPOINT_HOME} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 `}>
                        
                        <div className="flex flex-row gap-2 items-center">
                        <AiFillHome size={20}/>
                    <p className='text-[16px] font-semibold'>Main site</p>
                            </div>
                    </Link>
    
                    <Link to={ENDPOINT_DASHBOARD} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname ==='/dashboard'? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
                    
                    <div className="flex flex-row gap-2 items-center">
                        <BsCalendar2Event size={20}/>
                        <p className='text-[16px] font-semibold'>Upcoming Shows</p>
                            </div>
                    </Link>
                    <Link to={ENDPOINT_LINK_SALES} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname.includes('/link-sales')? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
                    
                    <div className="flex flex-row gap-2 items-center">
                        <BiSolidBookmarks size={20}/>
                        <p className='text-[16px] font-semibold'>Link sales</p>
                            </div>
                    </Link>
                    <Link to={ENDPOINT_PAST_SHOWS} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname.includes('/past-shows')? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
                
                    <div className="flex flex-row gap-2 items-center">
                        <BiSlideshow size={20}/>
                        <p className='text-[16px] font-semibold'>Past shows</p>
                        </div>
                    </Link>
                    <Link to={ENDPOINT_ANALYTICS} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname.includes('/past-shows')? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
            
            <div className="flex flex-row gap-2 items-center">
                <IoMdAnalytics size={20}/>
                <p className={`text-[16px] font-semibold`}>Sales analytics</p>
                </div>
            </Link>

            <Link to={ENDPOINT_ADVERTS} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname.includes('/past-shows')? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
        
        <div className="flex flex-row gap-2 items-center">
            <RiAdvertisementLine size={20}/>
            <p className={`text-[16px] font-semibold`}>Adverts</p>
            </div>
        </Link>


        <Link to={ENDPOINT_SETTINGS} className={`bg-white shadow-sm px-2 rounded-lg py-2 hover:border-l-4 hover:border-gray-600 hover:bg-gray-400 ${pathname.includes('/past-shows')? 'bg-gray-400 border-l-4 border-gray-600':''}`}>
        
        <div className="flex flex-row gap-2 items-center">
            <IoSettings size={20}/>
            <p className={`text-[16px] font-semibold`}>Settings</p>
            </div>
        </Link>
                </div>
        }
    </div>
  )
}

export default TopBar