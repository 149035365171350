import React, { useEffect, useState} from 'react'
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import apiService from '../../API/apiService';
import { toast } from 'react-toastify';

const LoginModal = ({show,setOnBoarding}) => {
    const [open, setOpen] = useState(true)
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [processing, setProcessing] = useState(false)


    const handleChange = (setState) => (event) => {
        setState(event.target.value)
    }
    const handleClose = () => {
        setOnBoarding(false);
      }

     const handleLogin = async (e) => {
        e.preventDefault()

        setProcessing(true)
        const payload = {
            username:name,
            password:password
        }

        console.log('payload',payload)
        try {
        const res = await apiService.post('/token-auth/', payload)
        console.log('success',res.data)
        localStorage.setItem('LS_ACCESS_TOKEN',JSON.stringify(res.data.token))
        setProcessing(false)
        toast('Login successfull')

        setName('')
        setPassword('')
        handleClose()
        }catch(error){
            console.error('erro', error)
            toast.error('Login failed')
            setName('')
            setPassword('')

        }
     } 

  return (
    <div
    // onClick={() => setOpen(false)}
    className='fixed h-screen flex justify-center font-satoshi items-center inset-0 bg-gray-900  bg-opacity-50 dark:bg-opacity-80 z-[999]'>
    <div
        onClick={(e) => e.stopPropagation()}
        className='relative w-full max-w-2xl bg-white rounded-lg py-4 px-8 z-50 flex flex-col gap-2 overflow-scroll hide-scrollbar max-h-[90%]'>
        <div className={`flex sticky top-0  justify-between bg-white`}>

                <p className='font-semibold text-lg'>Login</p>

            {/* <div
                onClick={() => setOpen(false)} className='cursor-pointer' >
                    <AiOutlineCloseCircle size={25}/>
                </div> */}
        </div>
        <form action="" className='w-full flex flex-col gap-2'>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[14px]'>Username</label>
                <input onChange={handleChange(setName)} value={name} type="text" className='w-full border text-[16px] p-2 rounded-md outline-none shadow-sm' />
            </div>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[14px]'>Password</label>
                <div className="flex flex-row w-full items-center justify-between border rounded-md shadow-sm">

                <input onChange={handleChange(setPassword)} value={password} type={showPassword?'text':'password'} className='flex-1  text-[16px] p-2  outline-none ' />
                <div onClick={() => setShowPassword(!showPassword)} className="p-2 cursor-pointer">
                    {showPassword ? 
                    <BsFillEyeFill size={20}/>
                    :
                    <BsFillEyeSlashFill size={20}/>
                    }
                </div>
                </div>
            </div>
            <div className="flex w-full">
                <button onClick={handleLogin} className={` ${name.length > 0 && password.length>0?'bg-gray-800 text-white':'bg-gray-200 pointer-events-none'} text-center text-[16px] rounded-md shadow-sm px-4 py-2`}>{processing?'Submitting':'Login'}</button>
            </div>
        </form>
        
    </div>
</div>
  )
}

export default LoginModal