import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  shows:[],
  currentPage:1,
  totalPages:0,

}


export const upcomingShowSlice = createSlice({
    name: 'upcomingShows',
    initialState,
    reducers:{
        setUpcomingShows: (state, action) => {
            state.shows = action.payload
        },

        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },

        setTotalPages: (state, action) => {
            state.totalPages = action.payload
        },

    }

})

const { setUpcomingShows,setCurrentPage,
    setTotalPages } = upcomingShowSlice.actions

const upcomingShowReducer =  upcomingShowSlice.reducer
export {
    upcomingShowReducer,     
    setUpcomingShows,
    setCurrentPage,
    setTotalPages,
};