import React, {useEffect, useState} from 'react'
import Sidebar from '../shared/Sidebar'
import { Outlet } from 'react-router-dom'
import TopBar from '../shared/TopBar';

const MainLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);
  return (
    <div className='font-satoshi flex flex-row bg-neutral-100 h-screen w-full overflow-hidden'>
       
            {/* sidebar */}
            {!isMobile &&
            <Sidebar/>
            }
        <div className="flex-1 h-screen w-full flex flex-col">
        <div className="w-full">
            {/* topbar */}
            {isMobile &&
            <TopBar/>
            }
        </div>
        <div className={`overflow-auto w-full hide-scrollbar py-6 min-h-0  px-0 md:px-4`}>

        <Outlet/>
        </div>
        </div>
    </div>
  )
}

export default MainLayout