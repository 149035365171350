import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomeCarousel = ({images}) => {
  return (
    <Carousel
    autoPlay
    // dynamicHeight={false}
    infiniteLoop
    swipeable
    interval={2000}
    showIndicators={false}
    showThumbs={false}
    >
        {images?.map((image) => (

    <div key={image?.id}  className='h-full md:h-[400px]  lg:h-[600px] xl:h-[600px]'>
        <img src={image?.image_url} />
    </div>
        ))}
</Carousel>
  )
}

export default HomeCarousel