import React, { useEffect, useState } from 'react'
import { BsArrowRightCircleFill } from "react-icons/bs";
import { toast } from 'react-toastify';
import apiService from '../../API/apiService';

const BuyLinkModal = ({show, amount,setToggleModal}) => {
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [enableButton, setEnableButton] = useState(false)
    const [processing, setProcessing] = useState(false)


    const handleNumberChange = (event) => {
        const inputNumber = event.target.value;
        if (/^\d{0,9}$/.test(inputNumber)) {
            setPhone(inputNumber);
        }
      }; 
    useEffect(() => {
        if(email !== '' && phone !== ''){
            setEnableButton(true)
        }else{
            setEnableButton(false)
        }    
    },[email, phone])


    const handleChange = (setState) => (event) => {
        setState(event.target.value)
    }

    const handleBuyLink = async() => {
        setProcessing(true)
        const payload = {
            email,
            phone:`254${phone}`,
            amount:amount,
            show:show
        }

        // console.log('payload',payload)
        try {
        const request = await apiService.post('/link/buy/', payload)
        // console.log('success',request)
        setProcessing(false)
        toast(request?.data?.success)
        setTimeout(() => {
            setToggleModal(false)
          }, 2000);
        setEmail('')
        setPhone('')

        
        } catch(error){
            // console.log('error',error)
            setProcessing(false)
            toast('Something went wrong')
            setTimeout(() => {
                setToggleModal(false)
              }, 2000);
            setPhone('')
            setEmail('')


        }
    }
  return (
    <div className='font-satoshi w-full h-full flex flex-col gap-1'>
    <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-1 w-full">
            <label htmlFor="" className='text-[16px]'>Email <span className='text-[11px] text-red-500'>( Required *)</span></label>
            <input onChange={handleChange(setEmail)}
            placeholder='Enter email address'
            value={email} type="text" className='py-2 px-2 rounded-md outline-none border border-gray-400' name="" id="" />
        </div>
        <div className="flex flex-col gap-1 w-full">
            <label htmlFor="" className='text-[16px]'>Phone number <span className='text-[11px] text-red-500'>( Required *)</span></label>
            <div className="flex flex-row border rounded-md">
                <div className="bg-gray-200 flex items-center p-2">
                    +254
                </div>
            <input onChange={handleNumberChange}
            placeholder='Enter phone number'
            value={phone} type="number" className='py-2 px-2 rounded-md outline-none w-full' name="" id="" />
            </div>
        </div>
        <div className="flex flex-row-reverse">
            <button
            onClick={handleBuyLink}
             className={` ${enableButton ? 'bg-[#39D2C0] text-white':'bg-gray-200 pointer-events-none'} flex flex-row justify-between items-center  gap-4 rounded-md px-4 py-1.5 `}>{processing ? "Requesting payment...":"Pay now"}
            <BsArrowRightCircleFill/>
            </button>
        </div>
    </div>
</div>
  )
}

export default BuyLinkModal