import React, { useState,useEffect, useRef } from 'react'
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs';
import GalleryCard from './GalleryCard';
import osoro from '../assets/images/osoro.png'
import kwach from '../assets/images/kwach.png'
import victor from '../assets/images/nyaata.png'
import ndenga from '../assets/images/ndenga.png'
import apiService from '../API/apiService';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import ModalLayout from './modals/ModalLayout';
import ImageModalLayout from './modals/ImageModalLayout';
import { motion, useScroll, useTransform } from "framer-motion";

function Gallery() {
      const target = useRef()
    const { scrollYProgress } = useScroll({
      target:target,
      offset: ["start end", "end start"]
    });
  
    const opacity = useTransform(scrollYProgress, [0,0.5],[0,1])
    const scale = useTransform(scrollYProgress, [0,0.5],[0.1,1])

  const [images, setImages] = useState([])
  const [pics, setPics] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [openImage, setOpenImage] = useState(null)
  const [openImg, setOpenImg] = useState()
  const [currentIndex, setCurrentIndex] = useState()


  const handleHover = (id) => {
    setCurrentIndex(id)
  }

  const handleCloseImage = () => {
    setOpenImage(null)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);

  const handleShowImage = (index) => {
    console.log('index',index)
    setOpenImage(index)
    setOpenImg(pics[index].image_url)
  }  

  const handleNextImage = () => {
    // let imageIndx = 
    let currentIndex = openImage + 1;
    currentIndex = currentIndex % pics.length; 
    console.log('currentIndex',currentIndex)
    setOpenImage(currentIndex)
    setOpenImg(pics[currentIndex].image_url)
  }
  const handlePrevImage = () => {
    let currentIndex = openImage
    if (currentIndex === 0) { // i would become 0
      currentIndex = pics.length; // so put it at the other end of the array
  }
  currentIndex = currentIndex - 1; 
    setOpenImage(currentIndex)
    setOpenImg(pics[currentIndex].image_url)
  }

  useEffect(()=> {
    const fetchImages = async () => {
      try {
        const response = await apiService.get(`/gallery/?com=CR`);
        console.log('res', response.data)
        setImages(response.data);
        setSelectedId(response?.data[0]?.id)
        setPics(response?.data[0]?.images)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchImages()
  },[])
  console.log('openImage',openImage===null)
  console.log('openImage',openImage)

  // console.log('openImg',openImg)
  // console.log('pics',pics.map((pic, index) => pic))



  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = Math.ceil(images.length / 10); // Calculate the number of slides
  // console.log('slides',slides)

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides) % slides);
  };

  const handleSelectGall = (image) => {
    if(selectedId=== image?.id){
      setPics([])
      setSelectedId()
    }else{

      setPics(image?.images)
      setSelectedId(image?.id)
    }
  }
  console.log('selectedId',selectedId)
  console.log('pics',pics)

  return (
    <>
    <div ref={target} style={{opacity, scale}} id='gallery' className=' px-2 relative'>
    <div className='flex flex-col gap-2 container   mx-auto px-6 py-8'>
      <div className="flex md:grid flex-col md:grid-cols-2 ">
        <p className='font-bold text-[60px] font-satoshi text-teal-400'>Our gallery</p>
        {images.length > 0 &&
        <div className="flex flex-row gap-5 flex-1 w-full overflow-x-scroll custom-scrollbar">
          {images?.map((gall) => (
        <div
        onClick={() =>handleSelectGall(gall)} 
        className={`cursor-pointer text-amber-600  font-satoshi capitalize text-[16px] md:text-[25px] flex flex-row justify-center items-center ${selectedId === gall?.id? 'underline underline-offset-4 decoration-teal-300 decoration-4':''}`}>
            {gall?.title.toLowerCase()}
        </div>
        ))}
        </div>
        }
        {/* {selectedId} */}
      </div>
      {/* {images.length > 0 &&
      <div className={` ${isMobile ? '' : 'auto-cols-min'} grid grid-flow-col hide-scrollbar overflow-x-scroll w-full gap-4`}>
        {images?.map((gall) => (
                        <GalleryCard id={gall?.id}  key={gall?.id} image={gall?.image_url} description={gall?.title} pics={gall?.images}
                        setPics={setPics}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}/>
        ))}
      </div>

} */}
          {images?.length === 0 &&
          <div className="">
            <p className='text-gray-400 text-xl font-satoshi'>No images at the moment</p>
          </div>
          }
          {selectedId !== null && pics && pics.length > 0 &&
          <div className="grid grd-cols-1 md:grid-cols-4 gap-2 w-full">
            {pics?.map((pic, index) => (
              <div 
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => setCurrentIndex()}

              className="h-full md:h-60 w-full relative">

                <img
                onClick={() => handleShowImage(index)}
                className="w-full h-full cursor-pointer object-cover rounded shadow-md relative"
                src={pic?.image_url}
                alt={pic?.id}
              />
          {!isMobile && <div className={`${currentIndex === index? 'hidden':'block'} absolute  inset-0 bg-black opacity-75  text-white  transition duration-300 `}>
          <div className="flex flex-col justify-end px-1 py-1 h-full">

          </div>
        </div>}
        </div>
            ))}
          </div>
      }

        </div>

    </div>
    {openImage !==null &&

    <ImageModalLayout setToggleModal={handleCloseImage}>
      <div className="py-6 relative w-full h-full">

      <img src={openImg} alt="" className='relative rounded shadow-2xl h-full object-cover w-full' />
      <div className="absolute inset-0  text-white transition duration-300">
            <div className="flex flex-row justify-between items-center px-1 h-full">
              <div onClick={handlePrevImage} className="cursor-pointer text-[16px] uppercase font-semibold mb-2">
                <HiOutlineArrowNarrowLeft size={20}/>
              </div>
              <div onClick={handleNextImage} className="cursor-pointer text-[16px] uppercase font-semibold mb-2">
                <HiOutlineArrowNarrowRight size={20}/>
              </div>

            </div>
          </div>
      </div>
    </ImageModalLayout>
    }
    </>
  )
}

export default Gallery