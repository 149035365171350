import React, { useState } from 'react'
import Seat from './Seat'

const SeatRow = ({availableSeats,handleClick,selectedSeats,theatre,handleClickSelectFroUnsel}) => {
  
    // console.log('selectedSeats',availableSeats)
    const Braeburn_ex = [{name:'J14', side:'R'},{name:'J15', side:'L'},{name:'K15', side:'R'},{name:'K16', side:'L'},{name:'L7', side:'R'},{name:'L8', side:'L'},{name:'M5', side:'R'},{name:'M6', side:'L'},{name:'N4', side:'R'},{name:'N5', side:'L'},{name:'O14', side:'R'},{name:'O15', side:'L'},{name:'P14', side:'R'},{name:'P15', side:'L'},{name:'Q5', side:'R'},{name:'Q6', side:'L'}, 
      {name:'A1', side:'N'},{name:'B1', side:'N'},{name:'C1', side:'N'},{name:'D1', side:'N'},{name:'E1', side:'N'},{name:'F1', side:'N'},{name:'G1', side:'N'},{name:'H1', side:'n'},{name:'I1', side:'N'},{name:'J1', side:'N'},{name:'K1', side:'N'},{name:'L1', side:'N'},{name:'M1', side:'N'},{name:'N1', side:'N'},
      {name:'A21', side:'N'},{name:'B22', side:'N'},{name:'C23', side:'N'},{name:'D24', side:'N'},{name:'E25', side:'N'},{name:'F26', side:'N'},{name:'G28', side:'N'},{name:'H30', side:'N'},{name:'I31', side:'N'},{name:'J28', side:'N'},{name:'K30', side:'N'},{name:'L14', side:'N'},{name:'M10', side:'N'},{name:'N8', side:'N'}]
    const Alliance_ex = [{name:'A3', side:'N'},{name:'A4', side:'L'},{name:'A17', side:'R'},{name:'A18', side:'N'},{name:'B4', side:'N'},{name:'B5', side:'L'},{name:'B19', side:'R'},{name:'B20', side:'N'},{name:'C5', side:'N'},{name:'C6', side:'L'},{name:'C19', side:'R'},{name:'C20', side:'N'},{name:'D6', side:'N'},{name:'D7', side:'L'},{name:'D21', side:'R'},{name:'D22', side:'N'},
  {name:'E7', side:'N'},{name:'E8', side:'L'},{name:'E21', side:'R'},{name:'E22', side:'N'},{name:'F8', side:'N'},{name:'F9', side:'L'},{name:'F23', side:'R'},{name:'F24', side:'N'},{name:'G1', side:'L'},{name:'G14', side:'R'},{name:'H1', side:'L'},{name:'H15', side:'R'},{name:'I1', side:'L'},{name:'I14', side:'R'}]

  const KNT_ex = [{name:'A9', side:'R'},{name:'A10', side:'L'},{name:'B10', side:'R'},{name:'B11', side:'L'},{name:'C12', side:'R'},{name:'C13', side:'L'},{name:'D12', side:'R'},{name:'D13', side:'L'},{name:'E13', side:'R'},{name:'E14', side:'L'},{name:'F12', side:'R'},{name:'F13', side:'L'},{name:'G13', side:'R'},{name:'G14', side:'L'},{name:'H12', side:'R'},{name:'H13', side:'L'}, 
    {name:'I13', side:'R'},{name:'I14', side:'L'},{name:'J12', side:'R'},{name:'J13', side:'L'},
    {name:'K13', side:'R'},{name:'K14', side:'L'},
    {name:'L12', side:'R'},{name:'L13', side:'L'},
    {name:'M13', side:'R'},{name:'M14', side:'L'},
    {name:'N12', side:'R'},{name:'N13', side:'L'},
    {name:'O7', side:'R'},{name:'O8', side:'L'},
    {name:'P5', side:'R'},{name:'P6', side:'L'},
    {name:'P7', side:'R'},{name:'P8', side:'L'},
    {name:'Q10', side:'R'},{name:'Q11', side:'L'},
    {name:'Q14', side:'R'},{name:'Q15', side:'L'},

    {name:'R10', side:'R'},{name:'R11', side:'L'},
    {name:'R14', side:'R'},{name:'R15', side:'L'},

    {name:'S10', side:'R'},{name:'S11', side:'L'},
    {name:'S14', side:'R'},{name:'S15', side:'L'},

    {name:'A1', side:'N'},{name:'B1', side:'N'},{name:'C1', side:'N'},{name:'D1', side:'N'},{name:'E1', side:'N'},{name:'F1', side:'N'},{name:'G1', side:'N'},{name:'H1', side:'n'},{name:'I1', side:'N'},{name:'J1', side:'N'},{name:'K1', side:'N'},{name:'L1', side:'N'},{name:'M1', side:'N'},{name:'N1', side:'N'},{name:'O1', side:'N'},{name:'P1', side:'N'},
    {name:'A19', side:'N'},{name:'B20', side:'N'},{name:'C24', side:'N'},{name:'D24', side:'N'},{name:'E26', side:'N'},{name:'F24', side:'N'},{name:'G26', side:'N'},{name:'H24', side:'N'},{name:'I26', side:'N'},{name:'J24', side:'N'},{name:'K26', side:'N'},{name:'L24', side:'N'},{name:'M26', side:'N'},{name:'N24', side:'N'},,{name:'O14', side:'N'},,{name:'P8', side:'N'},
  ]
  return (
    <div className="flex flex-row justify-center items-center gap-2 w-full">
        {availableSeats?.seats?.map((seat,index) => (
            
            <Seat key={index} 
            handleClickSelectFroUnsel={handleClickSelectFroUnsel}
            aisle={theatre === 1 ? Braeburn_ex?.some(seatT => seatT?.name === seat?.no):theatre ===2 ?  KNT_ex.some(seatT => seatT?.name === seat?.no):null}
            space_side={theatre === 1 ? Braeburn_ex?.find(seatT => seatT?.name === seat?.no?.side ?? 'N'):theatre ===2 ? KNT_ex?.find(seatT => seatT?.name === seat?.no)?.side ?? 'N':null}
            handleClick={handleClick} seatNo={seat.no} booked={selectedSeats.includes(seat?.no)} 
            occupied={seat.occupied}/>
        ))}
</div>
  )
}

export default SeatRow