import React, {useState} from 'react';

import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

const Card = ({name,image,fb, ig,desc,
  sub}) => {
    const [isHovered, setIsHovered] = useState(false)
  return (
    
  //   <div
  // onMouseEnter={() => setIsHovered(true)}
  // onMouseLeave={() => setIsHovered(false)}
  //    className="overflow-hidden shadow-lg rounded h-full cursor-pointer">
  //     <div className="relative h-full">
  //       <img
  //         className="w-full h-full object-cover"
  //         src={image}
  //         alt="Card Image"
  //       />
  //       {isHovered &&
  //       <div className={`absolute  inset-0 bg-black opacity-75  text-white  transition duration-300 ${isHovered ? '':''}`}>
  //         <div className="flex flex-col justify-end px-1 py-1 h-full">
  //           <h3 className="text-2xl font-bold mb-2">{name}</h3>
  //           <div className="flex flex-row gap-1">
  //               {/* <a href={fb} target="_blank" rel="noopener noreferrer">
  //                   <FaFacebookSquare size={30}/>
  //               </a> */}
  //               <a href={ig} target="_blank" rel="noopener noreferrer">
  //                   <FaInstagram size={30}/>
  //               </a>
  //           </div>
  //         </div>
  //       </div>}
  //     </div>
  //   </div>
  <>
  <div className="flex w-full h-full rounded-md">

  <div class="flip-card w-full h-full md:h-[400px]">
  <div class="flip-card-inner w-full h-full rounded-md">
    <div class="flip-card-front w-full h-full rounded-md">
    <img
          className="w-full h-full object-cover rounded-md"
          src={image}
          alt="Card Image"
        />
    </div>
    <div class="flip-card-back h-full w-full rounded-md">
    <div className="flex flex-col px-1 py-1 h-full gap-4">
            <h3 className="text-2xl text-left font-bold mb-2">{name}</h3>
            <p className='text-left'>{desc}</p>
            <p className='text-left'>{sub}</p>

            <div className="flex flex-row items-end gap-1">
                {/* <a href={fb} target="_blank" rel="noopener noreferrer">
                    <FaFacebookSquare size={30}/>
                </a> */}
                <a href={ig} target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30}/>
                </a>
            </div>
          </div>
    </div>
  </div>
</div>
  </div>
  </>
  );
};

export default Card;