import React, { useEffect, useState } from 'react'
import Navbar from '../components/shared/Navbar'
import Footer from '../components/shared/Footer'
import apiService from '../API/apiService';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bars } from  'react-loader-spinner'
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { v4 as uuidv4 } from 'uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { jwtDecode } from "jwt-decode";
import ReactPlayer from 'react-player'
// import ReactPlayer from 'react-player/lazy'




const Watch = () => {
    const {Id} = useParams()

    const [deviceId, setDeviceId] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    function disableDevtool() {
      // Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());
    
      function ctrlShiftKey(e, key) {
        return e.ctrlKey && e.shiftKey && e.key === key;
      }
    
      document.addEventListener("keydown", (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.key === "F12" ||
          ctrlShiftKey(e, "i") ||
          ctrlShiftKey(e, "j") ||
          (e.ctrlKey && e.key === "u")
        ) {
          e.preventDefault();
        }
      });
    }
    
    function devtoolTrap(isEnabled) {
      function recursiveFunction(counter) {
        if (typeof isEnabled === "string") {
          while (true) {}
        }
    
        if (`${isEnabled / isEnabled}`.length !== 1 || isEnabled % 20 === 0) {
          (() => true).constructor("debugger").call("action");
        } else {
          (() => false).constructor("debugger").apply("stateObject");
        }
        recursiveFunction(++counter);
      }
    
      try {
        if (isEnabled) {
          return recursiveFunction;
        }
    
        recursiveFunction(0);
      } catch (error) {}
    }
    
    // Initialize the devtools detection
    (() => {
      let devtoolsWindow;
      try {
        devtoolsWindow = {}.constructor("return this")();
      } catch (error) {
        devtoolsWindow = window;
      }
    
      devtoolsWindow.setInterval(devtoolTrap, 4000);
    })();
    disableDevtool();


    // http://127.0.0.1:8000/video-details/?video_id=3


      
      useEffect(() => {
        const getDeviceId = () => {
          const { userAgent } = navigator;
          const uniqueId = btoa(userAgent); // Encode the user agent string to create a unique ID
          const eviceId = uuidv4();
          setDeviceId(eviceId);
          setIsReady(true)
        };
  
      getDeviceId();
    }, []);


    const [fingerprint, setFingerprint] = useState('');

    const getDeviceFingerprint = async () => {
      const fp = await FingerprintJS.load();

      const result = await fp.get();
      const visitorId = result.visitorId;

      setFingerprint(visitorId);
      setIsReady(true)
      return visitorId
    };
    useEffect(() => {
  
      // getDeviceFingerprint();
    }, []);

    const [token, setToken] = useState(null);


    useEffect(() => {
        const fetchShow = async () => {
          if (isReady) {
            try {
              let speid = await getDeviceFingerprint();
              console.log('speid',speid)
              const response = await apiService.get('/video-details', {
                params: {
                  video_id: Id,
                  device_id: speid,
                },
              });
              // console.log(response.data);
              const toke = response.data.video_url
              setToken(response.data.video_url);
              const decodedTokenOne = jwtDecode(toke);
              const decodedTokenTwo = jwtDecode(decodedTokenOne.video);

              setVideoUrl(decodedTokenTwo);
              // console.log('decodedToken', decodedTokenTwo);
            } catch (error) {
                // console.log(error.response.data.error)
                if(error.response.status===403){
                    toast('Show can only be watched by the user who paid. Link cannot be shared');
                } else if(error.response.data.error==="This video link is expired"){
                  toast('Your video link is expired. Videos are only valid for seven days.')
                }
                else{
                    toast('An error occured please contact management');
                }
            }
          }
        };
    
        fetchShow();
      }, [isReady]); // Trigger the API call only when isReady changes

      // useEffect(() => {
      //   if (token) {
      //     const decodedToken = jwtDecode(token);
      //     setVideoUrl(decodedToken.video_url);
      //   }
      // }, [token]);
    
      // console.log('videoUrl',videoUrl.video_url)
  
    // console.log('deviceId',deviceId)
  return (
    <>
    {/* <Navbar/> */}
        <div className='w-full h-screen min-h-[80vh] font-satoshi'>
        <div className=" px-4 h-screen w-full ">
        <div className="flex flex-col md:flex-row py-2 h-screen w-full  items-center justify-center">
                <div className="flex flex-col video-player text-white h-screen w-full  items-center justify-center">
                  
                    {videoUrl ? (
                        <>
        <ReactPlayer
          url={"https://firebasestorage.googleapis.com/v0/b/cronyprod.appspot.com/o/files%2FHusband%20Home%20and%20Away.mp4?alt=media&token=956b2629-42cd-411d-b570-f61b4623302d"}
          className='react-player'
          playing
          width='100%'
          height='100%'
          volume={7}
          muted={true}
          light={true}
          controls={true}
          onError={() => {
            // Tell the user the video can not be loaded
            }}
          onEnded={() => {
            // thank user for watching
            }}
          onBuffer={() => (
            // tell user the video is buffering
            console.log('')
            )}
        />
                          {/* <Video autoPlay loop  
                            // controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                            // poster="http://sourceposter.jpg"
                            onCanPlayThrough={() => {
                                // Do stuff
                            }}>
                            <source src={videoUrl.video_url}  />
                        </Video> */}
                        {/* <video className='w-full h-full ' controls >
                        <source src={videoUrl.video_url} type="video/mp4"/>
                        </video> */}
                        </>
                        ) : (
                            
                            <div className="flex flex-col justify-center items-center">
                                <Bars
                                height="100"
                                width="100"
                                color="#ffffff"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                />
                            <p className='text-white text-[20px] text-center'>Loading video...</p>
                            </div>
                        )}
                </div>
        </div>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Watch