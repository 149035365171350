import React from 'react'

const AdDetailsModal = ({setToggleModal, ad}) => {
  return (
    <div className='w-full h-full rounded-md'>
        <a href={ad?.link ? ad?.link :ad?.url } target='_blank' className="">
            <img src={ad?.banner_url} alt="" className='rounded-md' />
        </a>
    </div>
  )
}

export default AdDetailsModal