import React, {useState,useEffect} from 'react'
import logo from '../../assets/images/clogobg.png'
import { Link } from 'react-router-dom'
import { ENDPOINT_HOME } from '../../routes'
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import {BiMailSend} from 'react-icons/bi'
import { FiMenu,FiX } from "react-icons/fi";
import navImage from "../../assets/images/footer-bg.png"

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const listenScrolling = () => {
      if(window.scrollY >= 170){
          setScrolled(true)
      } else {

          setScrolled(false)
      }
  }

  window.addEventListener('scroll', listenScrolling)
  // console.log('scrolled',scrolled)
  // console.log('window.scrollY',window.scrollY )
  // 723805
  return (
    <div 
    // className="bg-cover bg-center relative font-satoshi"
    style={{
      backgroundImage: `url(${navImage})`

    }}
    className={`${isMobile?' border-b border-gray-600  pb-6':''} w-full bg-cover bg-center  font-satoshi z-40 fixed ${scrolled? ' shadow-lg  shadow-gray-900 ':''}   shadow-lg  shadow-gray-900 `}
    >
         <div className="container w-full flex flex-col-reverse md:flex-col  px-4 mx-auto">
          {/* <div className={` ${isMobile && !openNav ? 'hidden':''} flex flex-col md:flex-row gap-2 justify-start md:justify-center py-2 border-b border-gray-600 items-start md:items-center`}>
            <div className="flex flex-row gap-2 justify-center items-center">
              <BsFillPhoneVibrateFill className='text-white' size={20}/>
            <p className='text-white text-[14px] md:text-[16px]'>+254700416519</p>
            </div>

            <div className="flex flex-row gap-2 justify-center items-center">
              <BiMailSend className='text-white' size={20}/>
            <p className='text-white text-[14px] md:text-[16px]'>thecronyproductions@gmail.com</p>
            </div>

          </div> */}
            <div className="flex flex-col md:flex-row justify-between">
              <div className="flex flex-row justify-between">

            <Link to={ENDPOINT_HOME} className="">
              <img src={logo} alt="" className='h-20' />
                {/* <p className='text-white'>THE CRONY</p> */}
            </Link>
            {isMobile &&
            <div onClick={() => setOpenNav(!openNav)} className="cursor-pointer items-center text-white justify-center flex flex-row">
                {openNav? 
                <FiX size={30} className='font-light'/>
                : 
                <FiMenu size={30}/>
                }
            </div>
            }
              </div>

            <div className={` ${isMobile && !openNav ? 'hidden':''} transition flex flex-col md:flex-row flex-start md:justify-center items-start md:items-center gap-4 md:gap-14`}>
                <a href='#home' className='text-white capitalize  text-[22px] font-semibold'>Home</a>
                <a href='#shows' className='text-white capitalize  text-[22px] font-semibold '>Previous Shows</a>
                <a href='#team' className='text-white capitalize  text-[22px] font-semibold'>About</a>
                <a href='#gallery' className='text-white capitalize  text-[22px] font-semibold'>Gallery</a>

            </div>
            <div className=""></div>
            
            </div>
         </div>
    </div>
  )
}

export default Navbar