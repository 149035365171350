import React, { useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import apiService from '../API/apiService';
import { toast } from 'react-toastify';


const Settings = () => {
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const [new_password, setNewPassword] = useState('')
    const [showNewPassword, setShowNewPassword] = useState(false)

    const [confirm_new_password, setNConfirmewPassword] = useState('')
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

    const [processing, setProcessing] = useState(false)

    const handleChange = (setState) => (event) => {
        setState(event.target.value)
    }

    const handleChangePassword = async (e) => {
        e.preventDefault()

        setProcessing(true)
        const payload = {
            old_password:password,
            new_password:new_password,
            confirm_new_password:confirm_new_password
        }

        let token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
        const options = {
            method: 'post',
            url: '/admin-change/passwords/', // Endpoint relative to the base URL
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${token}`,
            },
            data: payload,
          };
        try {
        const res = await apiService(options)
        setProcessing(false)
        setPassword("")
        setNewPassword("")
        setNConfirmewPassword("")
        toast('Password changed successfully')

        }catch(error){
            console.error('erro', error)
            toast.error('Password change failed')
            setPassword("")
            setNewPassword("")
            setNConfirmewPassword("")

        }
     } 

    const handleLogout = () => {
        navigate('/')
        localStorage.removeItem('LS_ACCESS_TOKEN')
    }
  return (
    <div  className='px-4 flex font-satoshi flex-col w-full gap-4'>
    <div className="flex flex-row w-full justify-between items-center gap-2">

    <p className='font-semibold text-[18px]'>Settings</p> 
    <button onClick={handleLogout} className='bg-gray-200 text-center px-4 py-2 rounded-md'>
  <div className="flex flex-row gap-2 items-center">
  <p className='text-[12px] md:text-[16px]'>Logout</p>
    </div>
  </button>
    </div>
    <div className="grid grid-cols-2 w-full gap-5">
    <div className="flex flex-col w-full gap-2">
        <p>Change password</p>
        <div className="flex flex-col gap-2">
        <form action="" className='w-full flex flex-col gap-2'>
            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[14px]'>Old Password</label>
                <div className="flex flex-row w-full items-center justify-between border rounded-md shadow-sm">

                <input onChange={handleChange(setPassword)} value={password} type={showPassword?'text':'password'} className='flex-1  text-[16px] p-2  outline-none ' />
                <div onClick={() => setShowPassword(!showPassword)} className="p-2 cursor-pointer">
                    {showPassword ? 
                    <BsFillEyeFill size={20}/>
                    :
                    <BsFillEyeSlashFill size={20}/>
                    }
                </div>
                </div>
            </div>

            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[14px]'>New Password</label>
                <div className="flex flex-row w-full items-center justify-between border rounded-md shadow-sm">

                <input onChange={handleChange(setNewPassword)} value={new_password} type={showNewPassword?'text':'password'} className='flex-1  text-[16px] p-2  outline-none ' />
                <div onClick={() => setShowNewPassword(!showNewPassword)} className="p-2 cursor-pointer">
                    {showNewPassword ? 
                    <BsFillEyeFill size={20}/>
                    :
                    <BsFillEyeSlashFill size={20}/>
                    }
                </div>
                </div>
            </div>

            <div className="flex flex-col gap-1 w-full">
                <label htmlFor="" className='text-[14px]'>Confirm New Password</label>
                <div className="flex flex-row w-full items-center justify-between border rounded-md shadow-sm">

                <input onChange={handleChange(setNConfirmewPassword)} value={confirm_new_password} type={showConfirmNewPassword?'text':'password'} className='flex-1  text-[16px] p-2  outline-none ' />
                <div onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} className="p-2 cursor-pointer">
                    {showConfirmNewPassword ? 
                    <BsFillEyeFill size={20}/>
                    :
                    <BsFillEyeSlashFill size={20}/>
                    }
                </div>
                </div>
            </div>
            <div className="flex w-full">
                <button onClick={handleChangePassword} className={` ${confirm_new_password.length > 0 && new_password.length > 0 && password.length>0?'bg-gray-800 text-white':'bg-gray-200 pointer-events-none'} text-center text-[16px] rounded-md shadow-sm px-4 py-2`}>{processing?'Submitting':'Change password'}</button>
            </div>
        </form>
        </div>
    </div>
    </div>

    </div>
  )
}

export default Settings