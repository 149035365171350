import React, { useState, useEffect, useRef } from 'react';
import ModalLayout from './modals/ModalLayout';
import AdDetailsModal from './modals/AdDetailsModal';
import AdModalLayout from './modals/AdModalLayout';

const ImageSlider = ({ ads }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showAdvModal, setShowAdvModal] = useState(null)
    
    const handleCloseModal = () => {
        setShowAdvModal(null)
    }
  

    const imageRef = useRef(null);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        const nextIndex = (currentIndex + 1) % ads.length;
        setCurrentIndex(nextIndex);
      }, 3000); // Change interval (in milliseconds) for desired scroll speed
  
      return () => clearInterval(intervalId);
    }, [ads, currentIndex]);

    console.log('currentIndex',currentIndex)
  
    useEffect(() => {
      const imageWidth = imageRef.current.clientWidth;
      imageRef.current.scrollLeft = currentIndex * imageWidth;
    }, [currentIndex]);
  
    return (
        <>
      <div className="relative overflow-hidden w-full h-full">
        <div
          ref={imageRef}
          className="flex transition-all duration-1000 ease-in-out"
        >
          {/* {ads.map((ad, index) => ( */}
            <div  onClick={() => setShowAdvModal(ads[currentIndex])} className="flex-none w-full">
              <div className="h-60 w-full object-contain rounded-md shadow-md">
                <img
                  src={ads[currentIndex]?.banner_url}
                  alt={`Image ${currentIndex + 1}`}
                  className="w-full h-full aspect-square rounded"
                />
              </div>
            </div>
          {/* ))} */}
        </div>
      </div>
      {showAdvModal &&
    <AdModalLayout open={showAdvModal} setToggleModal={handleCloseModal} title={``}>
      <AdDetailsModal setToggleModal={handleCloseModal} ad={showAdvModal}/>
    </AdModalLayout>
    }
        </>
    );
  };
  
  export default ImageSlider;
