import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import { ADMIN_SEAT, AuthenticatedRoute, ENDPOINT_ADVERTS, ENDPOINT_ANALYTICS, ENDPOINT_DASHBOARD, ENDPOINT_HOME, ENDPOINT_LINK_SALES, ENDPOINT_PAST_SHOWS, ENDPOINT_SEAT, ENDPOINT_SETTINGS, ENDPOINT_WATCH_SHOW } from './routes';
import Watch from './pages/Watch';
import MainLayout from './components/layout/MainLayout';
import Dashboard from './pages/Dashboard';
import LinkSales from './pages/LinkSales';
import PastShows from './pages/PastShows';
import SeatMap from './pages/SeatMap';
import DashMap from './pages/DashMap';
import Analytics from './pages/Analytics';
import Settings from './pages/Settings';
import Adverts from './pages/Adverts';

function App() {

  return (
    <>
    <Routes>
      <Route element={<Home/>} path={ENDPOINT_HOME}/>
      <Route element={<SeatMap/>} path={ENDPOINT_SEAT}/>

      <Route element={<Watch/>} path={ENDPOINT_WATCH_SHOW}/>
      <Route element={<AuthenticatedRoute />}>

      <Route path={ENDPOINT_DASHBOARD} element={<MainLayout/>}>

        <Route path={ENDPOINT_DASHBOARD} element={<Dashboard/>}/>
        <Route path={ENDPOINT_LINK_SALES} element={<LinkSales/>}/>
        <Route path={ENDPOINT_PAST_SHOWS} element={<PastShows/>}/>
        <Route path={ADMIN_SEAT} element={<DashMap/>}/>

        <Route path={ENDPOINT_ANALYTICS} element={<Analytics/>}/>
        <Route path={ENDPOINT_ADVERTS} element={<Adverts/>}/>
        <Route path={ENDPOINT_SETTINGS} element={<Settings/>}/>

      </Route>
      </Route>
    </Routes>
      <ToastContainer 
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      />

    </> 
  );
}

export default App;
