import React, { useEffect, useState } from 'react'
import apiService from '../API/apiService';
import Loader from '../components/shared/Loader';
import Paginator from '../components/shared/Paginator';

const LinkSales = () => {
    const [ticketSales, setTicketSales] = useState([])
    const [loading , setLoading] = useState(false)
    const [currentPage , setCurrentPage] = useState(1)
    const [totalPages , setTotalPages] = useState(0)

    useEffect(() => {
      const fetchTicketSales = async () => {
              setLoading(true)
        let token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
        const options = {
          authorization:`Token ${token}`
        }
        try {
          const response = await apiService.get(`/link-sales/?page_size=20&page=${currentPage}`, {headers:options});
          setTicketSales(response.data);
          setCurrentPage(response?.data?.current_page_number)
          setTotalPages(response?.data?.total_pages)
                setLoading(false)
        } catch (error) {
          console.error('Error fetching data:', error);
                setLoading(false)
        }
      };
  
      fetchTicketSales();
    }, [currentPage]);
  
    // console.log('ticketSales',ticketSales)
  return (
    <>
    {loading?
    <Loader/>
    :

    <div  className='px-4 flex font-satoshi flex-col w-full gap-4'>
        <p className='font-semibold text-[18px]'>Ticket sales</p> 
        
        {ticketSales?.results?.length>0 ?
        <>
      <div className="pt-2  overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-600'>Email</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-600'>Phone number</th>
                          {/* <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-600'>Show</th> */}
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-600'>Amount(Ksh)</th>

                        </tr>
                      </thead>
                      <tbody>
                        {ticketSales?.results?.map((sale) => (

                            <tr className='p-2 border-b border-b-gray-200 relative'>
                              <td className='pl-2 py-2'>
                              <p className='text-xs md:text-[14px] '>{sale?.email}</p>

                              </td>
                              <td className='pl-2 py-2'>
                                  <p className='text-xs md:text-[14px] '>+{sale?.phone_number}</p>
                                
                              </td>
                              {/* <td className='pl-2 py-2'>
                                <p className='text-[14px]'>{sale?.name}</p>
                              </td> */}
                              <td className='pl-2 py-2'>
                              <p className='text-xs md:text-[14px]'>{sale?.amount_paid}</p>
 
                              </td>
                            </tr>
                        ))}



                      </tbody>
                    </table>
    </div>
    <Paginator currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage}/>

        </>
    :
      <div className="flex flex-row justify-center items-center">
        <p className='text-[18px]'>No any link sales at the moment</p>
      </div>
    }
    </div>
    }
    </>
  )
}

export default LinkSales