import React from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";

const ImageModalLayout = ({ setToggleModal, ...props }) => {
    
  return (
    <div
    onClick={() => setToggleModal(false)}
    className='fixed h-screen flex justify-center font-satoshi items-center inset-0 bg-gray-900  opacity-95 z-[999]'>
    <div
        onClick={(e) => e.stopPropagation()}
        className='relative w-full max-w-6xl rounded-lg py-4 px-8 z-50 flex flex-col gap-2 overflow-scroll hide-scrollbar max-h-[90%]'>
        <div className={`flex sticky top-0  ${props.title ? 'justify-between' : "justify-end"}`}>
            {props.title && (
                <p className='font-semibold text-lg'>{props.title}</p>
            )}
            <div
                onClick={() => setToggleModal(false)} className='cursor-pointer' >
                    <AiOutlineCloseCircle size={25} className='text-white'/>
                </div>
        </div>
        <div className="h-full md:h-screen w-full ">

        {props.children}
        </div>
    </div>
</div>
  )
}

export default ImageModalLayout