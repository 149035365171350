import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { IoAddOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";
import { handleUploadFilesAsync } from '../../utils/actions';
import apiService from '../../API/apiService';

const AddShowFormModal = ({setToggleModal}) => {
    const [name, setName] = useState('')
    const [day, setDay] = useState('')
    const [time, setTime] = useState('')
    const [venue, setVenue] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [djDate, setDjDate] = useState('');
    const [price, setPrice] = useState('')
    const [processing, setProcessing] = useState(false);
    const [venues, setVenues] = useState([])
    const [buttonText, setButtonText] = useState('Save')
    const [venueRows, setVenueRows] = useState([])





    useEffect(() => {
        const fetchVenues = async () => {
          try {
            const response = await apiService.get(`/venues`);
            setVenues(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchVenues();
      }, []);

      useEffect(() => {
        const fetchSeatRows = async () => {
          try {
            const response = await apiService.get(`/seat-rows/?venue=${venue.value}`);
            setVenueRows(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchSeatRows();
      }, [venue]);

      console.log('venueRows',venueRows)
      
      const convertToDjangoDateTime = (dateString) => {
        const dateObj = new Date(dateString);
        const djangoDateTime = dateObj.toISOString();
        
        return djangoDateTime;
      };
      
      // console.log('startDate', startDate)
      // console.log('DJdATE', djDate)






    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        console.log('Called',file)
        setPreviewURL(URL.createObjectURL(file));
      };

      const handleCoverChange = (event) =>  {
        setSelectedFile(event.target.files[0]);
        const uploadedImage = event.target.files[0];
        setPreviewURL(URL.createObjectURL(uploadedImage));
    
        }
    
    const handleDeleteVideo = () => {
        setSelectedFile(null)
        setPreviewURL('')
    }  

    const handleChangeSelect = (setState) => (value) => {
        setState(value);
      };

      // console.log('Selected Option:', time);


    

const token = JSON.parse(localStorage.getItem('LS_ACCESS_TOKEN'))
const handleCreateShow = async () => {
    setProcessing(true)
    setButtonText('Uploading image...')
    try {
      const downloadURL = await handleUploadFilesAsync(selectedFile);
      if (downloadURL) {
        // sendDataToAPI(downloadURL);
        // console.log('downloadURL',downloadURL);
        setProcessing(false)
        setButtonText('Saving...')


      } else {
        // console.error('Error uploading file');
        setProcessing(false)
        setButtonText('Save')

      }
      const payload = {
        name:name,
        day:day?.value,
        time:day?.value === 'FRIDAY'? 6 : time?.value,
        venue:venue?.value,
        image_url:downloadURL,
        date:djDate,
        price:price,

      }
      const options = {
          authorization:`Token ${token}`
        }
      console.log('payload', payload)
      const request = await apiService.post('/add-show/', payload)
      setProcessing(false)
      const seatRows = venueRows.map((item) => item.value);
      const payl = {
        price:1000,
        show_id:request?.data?.id,
        seat_rows:seatRows

      }
      console.log('payl', payl)

      const req = await apiService.post('/add-ticket/', payl)

      toast(request?.data?.success)
                      setTimeout(() => {
        setToggleModal(false)
      }, 2000);
      setName('')
        setDay('')
        setTime('')
        setPrice('')
        // setVenue('')
        setSelectedFile(null)
        setPreviewURL('')
      setButtonText('Save')

    } catch (error) {
      console.error('Error handling file upload:', error);
      toast('Error occured during upload')
                      setTimeout(() => {
        setToggleModal(false)
      }, 2000);
      setProcessing(false)
      setButtonText('Save')
      setName('')
      setDay('')
      setTime('')
      setVenue('')
      setPrice('')
      setSelectedFile(null)
      setPreviewURL('')

    }
  };

  // console.log('selectedFile',selectedFile)
  



    const showDay = [
    { value: 'FRIDAY', label: 'Friday' },
    { value: 'SATURDAY', label: 'Saturday' },
    { value: 'SUNDAY', label: 'Sunday' }
    ]

    const showTime = [
        { value: 3, label: '15:00 PM' },
        { value: 6, label: '18:30 PM' }
        ]
    const showVenue = [
        { value: 1, label: 'KNT' },
        { value: 2, label: 'Nairobi cinema' }
        ]    

  return (
    <div className='flex flex-col w-full h-full gap-2'>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Name</label>
        <input value={name} onChange={(e) => setName(e.target.value)} type="text" className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Price</label>
        <input value={price} type="number" onChange={(e) => setPrice(e.target.value)} min={0} className='outline-none px-2 py-2 rounded-md border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Day</label>
            <Select value={day} options={showDay} onChange={handleChangeSelect(setDay)} />
        </div>
        {day?.value !== 'FRIDAY' &&
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Time</label>
            <Select value={time} options={showTime} onChange={handleChangeSelect(setTime)}/>
        </div>
        }
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Date</label>
            <DatePicker selected={startDate} onChange={(date) => {setStartDate(date)
            setDjDate(convertToDjangoDateTime(date))
            }} className='w-full px-2 py-2 rounded-md outline-none border border-gray-300' />
        </div>
        <div className="flex flex-col gap-1">
            <label htmlFor="" className='text-[14px]'>Venue</label>
            <Select value={venue} options={venues} onChange={handleChangeSelect(setVenue)}/>
        </div>
        
        <div className="flex flex-col gap-1">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Cover photo
              </label>
              <div className="flex flex-row gap-1 items-center">
              <div className="mt-2 w-20 h-20 flex  items-center justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 "
                    >
                      <span>
                        <IoAddOutline size={40}/>
                      </span>
                      <input
                      accept="image/*"
                      onChange={handleCoverChange}
                       id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                  </div>
                </div>
              </div>
              {selectedFile && (
                <div className="w-20 h-20 relative">
                    <img src={previewURL} className='w-full h-full object-cover rounded' alt="" />
                    <div onClick={handleDeleteVideo} className="absolute cursor-pointer right-0 -top-2">
        <AiOutlineCloseCircle size={30} className='text-red-600'/>
        </div>
                </div>
            )}
              </div>
            </div>

        
        <div className="flex flex-row-reverse">
            <button onClick={handleCreateShow} className='px-4 py-1.5 text-center rounded-md bg-gray-300'>{buttonText}</button>
        </div>
    </div>
  )
}

export default AddShowFormModal