import React from 'react'
import Navbar from '../components/shared/Navbar'
import Landing from '../components/Landing'
import Shows from '../components/Shows'
import Footer from '../components/shared/Footer'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import { motion, useScroll, useTransform } from "framer-motion";
import { HiArrowLongDown, HiArrowLongUp } from "react-icons/hi2"
import { Helmet } from 'react-helmet';

const Home = () => {
  const { scrollYProgress } = useScroll();
  return (
    <>
    <div className="">
    <Helmet>
        <title>Crony Productions</title>
        <meta name="description" content="Crony Productions is a leading entertainment company in Nairobi, bringing laughter and joy through stand-up comedy shows, theatre productions, and various comic acts. Crony Productions is your destination for top-notch entertainment. We feature talented comedians and put on theater shows that guarantee a night full of laughter and fun. Join us for a memorable experience filled with comic plays, skits, and entertainment performances that will leave you wanting more. Check out our upcoming events and get ready to laugh your heart out!" />
        <meta name="keywords" content="Crony Productions, Kenyan comedy, stand-up comedy Nairobi, comedy shows Nairobi, theatre shows Nairobi, entertainment Nairobi, comedians Kenya,Crony Productions, Kenyan comedy, stand-up comedy Kenya, comedy shows Kenya, theatre shows Kenya, entertainment Kenya, comedians Kenya,Crony Productions, comedy, stand-up comedy , comedy shows , theatre shows , entertainment , comedians,comedy theatre shows Nairobi, stand-up comedy shows Nairobi, family entertainment Nairobi, live entertainment Nairobi, upcoming shows Nairobi,comedy theatre shows Kenya, stand-up comedy shows Kenya, family entertainment Kenya, live entertainment Kenya, upcoming shows Kenya,comedy theatre shows , stand-up comedy shows , family entertainment , live entertainment , upcoming shows,comedy shows near me, comedy shows in Alliance francaise, Nairobi, theatre shows in Kenya national theatre, Nairobi , theatre shows in KNT, Nairobi,Looking for a fun night out in Nairobi? Crony Productions brings laughter and entertainment to your doorstep,Immerse yourself in the world of laughter and live entertainment with Crony Productions' theatre shows! From stand-up comedy to captivating plays, discover a variety of shows for all ages,osoro osoro Kenya, Kenyan comedian, victor nyaata Nairobi, see nick kwach live,Entertainment,Comedy,Comedians,Theater shows,Comic plays,Skits,Live performances,Entertainment events,Stand-up comedy,Theatre productions,Comedy shows " />
        <meta property="og:title" content="Crony Productions" />
        <meta property="og:description" content="Crony Productions is a leading entertainment company in Nairobi, bringing laughter and joy through stand-up comedy shows, theatre productions, and various comic acts. Crony Productions is your destination for top-notch entertainment. We feature talented comedians and put on theater shows that guarantee a night full of laughter and fun. Join us for a memorable experience filled with comic plays, skits, and entertainment performances that will leave you wanting more. Check out our upcoming events and get ready to laugh your heart out!" />
        <meta name="twitter:title" content="Crony Productions" />
        <meta name="twitter:description" content="Crony Productions is a leading entertainment company in Nairobi, bringing laughter and joy through stand-up comedy shows, theatre productions, and various comic acts. Crony Productions is your destination for top-notch entertainment. We feature talented comedians and put on theater shows that guarantee a night full of laughter and fun. Join us for a memorable experience filled with comic plays, skits, and entertainment performances that will leave you wanting more. Check out our upcoming events and get ready to laugh your heart out!" />

      </Helmet>
    </div>
    <div className="relative">

          <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    <Navbar/>
      <Landing/> 
      {/* <div className="h-[400px]"></div> */}
      <Shows/>
      <Team/>
      <Gallery/>
      <Footer/>
      <a href='#home' className="absolute right-0 bottom-0 animate-bounce   bg-amber-700  rounded-full p-4">
            {/* <div className="bg-white"> */}
                <HiArrowLongUp size={25}/>
            {/* </div> */}
        </a>
    </div>
    </>
  )
}

export default Home