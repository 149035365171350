
import React, { useState, useEffect} from 'react'

import { useSearchParams } from 'react-router-dom'
import { Puff } from  'react-loader-spinner'
import apiService from '../API/apiService'
import Stage from '../components/Stage'
import SeatRow from '../components/SeatRow'
import { toast } from 'react-toastify'

// 
const DashMap = () => {
    let currentUrlParams = new URLSearchParams(window.location.search)
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [seatRows , setSeatRows] = useState([])
    const [show , setShow] = useState()

    const [selectedSeats, setSelectedSeats] = useState([])
    const [selectedSeat, setSelectedSeat] = useState("")
    const [processing, setProcessing] = useState(false)
    const [ticketModal, setTicketModal] = useState(false)
    const [theatre , setTheatre] = useState()


    const handleClose = () => {
        setTicketModal(false)
    }

    const[showDay, setShowDay] = useState(
        currentUrlParams.has('day') ? currentUrlParams.get('day') : ''
        )
    const[showTime, setShowTime] = useState(
            currentUrlParams.has('time') ? currentUrlParams.get('time') : ''
            )    


    const handleClick = (seatName) => {
        if (selectedSeats.includes(seatName)) {
            setSelectedSeats(selectedSeats.filter((seatnumber) => seatnumber !== seatName));
        } else {
            setSelectedSeats([...selectedSeats, seatName]);

        }
    }

    const handleClickSelectFroUnsel = (seatName) => {
        setSelectedSeat(seatName)
    }

    const fetchShows = async () => {
        setProcessing(true)
      try {
        const response = await apiService.get(`/upcoming-show/?day=${showDay.toUpperCase()}&time=${showTime}`);
        setTheatre(response.data.venue.id)
        setShow(response.data)
        console.log(response.data)
        const groupedSeats = response.data.tickets[0].seat_rows.map((rowNumber,index) => {
            const rowSeats = response.data.tickets[0].all_seats.filter(
              (seat) => seat.no.charAt(0) === String.fromCharCode(64 + index+1)
            );
        
            return {
              row: String.fromCharCode(64 +  index+1),
              seats: rowSeats,
            };
          });
          console.log('groupedSeats',groupedSeats)
          setSeatRows(groupedSeats);
        // seatRows?.tickets[0]?.all_seats
        setProcessing(false)

      } catch (error) {
        console.error('Error fetching data:', error);
        setProcessing(false)

      }
    };

    useEffect(() => {
        fetchShows();
      }, [showTime,showDay]);



      const handleBuyTicket = async() => {
          setProcessing(true)
          const payload = {
              day:showDay,
              time_slot:showTime,
              show:show.id,
              selected_seats:selectedSeats
          }
          // if (selectedTicket === 'C'){
          //     payload.amount=1500
  
          // }
  
          console.log('payload',payload)
          try {
          const request = await apiService.post('/map-manual-show-sell/', payload)
          setProcessing(false)
          toast(request?.data?.success)
          fetchShows();

        //   setToggleModal(false)
          
          } catch(error){
              // console.log('error',error)
              setProcessing(false)
              // setToggleModal(false)
                fetchShows();

            //   toast.error('Something went wrong')
  
          }
      }

      const handleUnBuyTicket = async() => {
        setProcessing(true)
        const payload = {
            show:show.id,
            selected_seat:selectedSeat
        }
        console.log('payload',payload)
        try {
        const request = await apiService.post('/dash/manual-show-unsell/', payload)
        setProcessing(false)
        // toast(request?.data?.success)
        selectedSeat("")
        fetchShows();

      //   setToggleModal(false)
        
        } catch(error){
            // console.log('error',error)
            setProcessing(false)
        fetchShows();
            // setToggleModal(false)
            // toast.error('Something went wrong')

        }
    }
      

    useEffect(() => {
    //   const fetchPastShows = async () => {
    //     try {
    //       const response = await apiService.get(`/seat-rows/?venue=1`);
    //       setSeatRows(response.data);
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };
  
    //   fetchPastShows();
    if(selectedSeat){
        handleUnBuyTicket()
    }
    }, [selectedSeat]);
    // console.log('selectedSeat',selectedSeat)
    // console.log('selectedSeats',selectedSeats)
  return (
    <>
    {processing ?
    <div className="px-4 flex w-screen h-screen justify-center items-center flex-col">
        <Puff
        height="100"
        width="100"
        radius={1}
        color="#374151"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        />
        </div>
        :
        <div className='w-auto  md:w-screen text-white font-satoshi bg-gray-200 h-screen overflow-x-auto custom-scrollbar'>
        <div className="container mx-auto  w-full h-full py-2 px-20">
            <div className="flex justify-center items-center flex-col gap-4 w-full">
                <div className="flex flex-row justify-center bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] gap-4 items-center py-4 px-6">
                <div className="flex flex-row gap-1">
                        <div className="bg-gray-600 w-6 h-6 rounded-sm shadow-md p-2"></div>
                        <p>Unbooked</p>
                    </div>
                    <div className="flex flex-row gap-1">
                        <div className="bg-teal-500 w-6 h-6 rounded-sm shadow-md p-2"></div>
                        <p>Selected</p>
                    </div>
                    <div className="flex flex-row gap-1">
                        <div className="bg-white w-6 h-6 rounded-sm shadow-md p-2"></div>
                        <p>Booked</p>
                    </div>
                    <div className="flex flex-row gap-1">
                        <div className="bg-yellow-800 w-6 h-6 rounded-sm shadow-md p-2"></div>
                        <p>Aisle</p>
                    </div>

                </div>
                <div className="flex justify-center items-center flex-col gap-3 pl-[40rem] md:pl-16 w-full">

                {seatRows?.map((seatRow) => (
                    <SeatRow handleClickSelectFroUnsel={handleClickSelectFroUnsel} theatre={theatre} selectedSeats={selectedSeats} handleClick={handleClick} availableSeats={seatRow}/>
                ))}

            {selectedSeats.length > 0 &&
                <div className="flex felx-row items-center justify-center">
                    <button onClick={handleBuyTicket} className={`text-center bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] text-white border border-gray-600 px-8 py-2 rounded-sm shadow-xl drop-shadow-2xl `}>Purchase</button>
                </div>}
                {/* {selectedSeats.length > 0 &&
                <div className="flex felx-row items-center justify-center">
                    <button onClick={() => setTicketModal(true)} className={`text-center bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] text-white border border-gray-600 px-8 py-2 rounded-sm shadow-xl drop-shadow-2xl `}>Purchase</button>
                </div>} */}
            
                </div>
            </div>
        </div>
    </div>
    // <div className='w-full md:w-screen text-white font-satoshi bg-[#242333] h-full md:h-screen overflow-hidden'>
    //     <div className="container mx-auto  w-full h-full py-2 px-2">
    //         <div className="flex justify-center items-center flex-col gap-4 w-full">
    //             <Stage/>
    //             <div className="flex flex-row justify-center bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] gap-4 items-center py-4 px-6">
    //             <div className="flex flex-row gap-1">
    //                     <div className="bg-gray-600 w-6 h-6 rounded-sm shadow-md p-2"></div>
    //                     <p>Unbooked</p>
    //                 </div>
    //                 <div className="flex flex-row gap-1">
    //                     <div className="bg-teal-500 w-6 h-6 rounded-sm shadow-md p-2"></div>
    //                     <p>Selected</p>
    //                 </div>
    //                 <div className="flex flex-row gap-1">
    //                     <div className="bg-white w-6 h-6 rounded-sm shadow-md p-2"></div>
    //                     <p>Booked</p>
    //                 </div>

    //             </div>
    //             <div className="flex justify-center items-center flex-col gap-3 w-full">

    //             {seatRows?.map((seatRow) => (
    //                 <SeatRow selectedSeats={selectedSeats} handleClick={handleClick} availableSeats={seatRow}/>
    //             ))}

    //             {selectedSeats.length > 0 &&
    //             <div className="flex felx-row items-center justify-center">
    //                 <button onClick={handleBuyTicket} className={`text-center bg-gradient-to-r from-[#242333] via-gray-400 to-[#242333] text-white border border-gray-600 px-8 py-2 rounded-sm shadow-xl drop-shadow-2xl `}>Purchase</button>
    //             </div>}
            
    //             </div>
    //         </div>
    //     </div>
    // </div>
        }

    </>
  )
}

export default DashMap