import React, { useEffect, useState, useRef } from 'react'
import ModalLayout from './modals/ModalLayout'
import TicketModal from './modals/TicketModal'
import apiService from '../API/apiService'
import theaterImage from '../assets/images/kwach.png'
import { HiChevronDown } from "react-icons/hi";
import { BiCalendarEvent } from "react-icons/bi";
import { toast } from 'react-toastify';
import FirstPastShow from './FirstPastShow'
import { Puff } from  'react-loader-spinner'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ENDPOINT_SEAT } from '../routes'
import { motion, useScroll, useTransform } from "framer-motion";
import { HiArrowLongDown } from "react-icons/hi2"
import logo from '../assets/images/clogo.jpeg'
import HomeCarousel from './HomeCarousel'





function Landing() {
    const navigate = useNavigate()
    const target = useRef()
    const { scrollYProgress } = useScroll({
      target:target,
      offset: ["end end", "end start"]
    });
  
    const opacity = useTransform(scrollYProgress, [0,0.5],[1,0])
    const scale = useTransform(scrollYProgress, [0,0.5],[1,0.8])

    let currentUrlParams = new URLSearchParams(window.location.search)
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [openTicketModal, setOpenTicketModal] = useState('')
    const [show, setShow] = useState('')
    const [showAmount, setShowAmount] = useState(1500)
  
    const [amount, setAmount] = useState('')
    const [ticketTime, setTicketTime] = useState('')
    const [showTime, setShowTime] = useState('')
    const [showDay, setShowDay] = useState('')
    const [showDays, setShowDays] = useState(false)
    const [showTimeSlots, setShowTimeSlots] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [images, setImages] = useState([])


  useEffect(()=> {
    const fetchImages = async () => {
      try {
        const response = await apiService.get(`/gallery/?com=CR`);
        console.log('res', response.data)
        setImages(response.data.flatMap(gallery => gallery.images));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchImages()
  },[])

  console.log('images',images)


    const seatMap = () => {
        // currentUrlParams.set('day', showDay.toUpperCase())
        // currentUrlParams.set('time', showTime)
        // setUrlParams(currentUrlParams)
        if(showTime !=='' && showDay !==''){
            navigate(`${ENDPOINT_SEAT}/?day=${showDay.toUpperCase()}&time=${showTime}`)
        }else{

            toast.error('Select day and time')
        }
    }


    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);



    useEffect(() => {
        const fetchShows = async () => {
            setProcessing(true)
          try {
            const response = await apiService.get(`/upcoming-show/?day=${showDay.toUpperCase()}&time=${showTime}`);
            setShow(response.data);
            // console.log(response.data)
            setShowAmount(response.data?.price)

            setProcessing(false)

          } catch (error) {
            console.error('Error fetching data:', error);
            setProcessing(false)

          }
        };
    
        fetchShows();
      }, [showTime,showDay]);

    // useEffect(() => {
    //     if (show !== '' || show !== null || show !== undefined){

    //         setShowTime(show.time)
    //         setShowDay(show.day)
    //     }
  
    // },[])

      // console.log('show',show)
      // console.log('showTime',showTime)
      // console.log('showDay',showDay)
      console.log((showDay !== "" || showTime !== "") && show !== "")

    
    const handleCloseTicketModal = () => {
        setOpenTicketModal('')
        }
    const handleTicketClick = (ticket,amt) => {
        if(showTime !=='' && showDay !==''){

            setOpenTicketModal(ticket)
            setAmount(amt)
        }else{
            toast.error("Select the day and time slot you want to book a ticket for!");
        }
    }    

    const handleSelectDay = (day) => {
        setShowDay(day)
        // setShowDays(false)
    }

    const handleSelectTimeSlot = (slot) => {
        setShowTime(slot)
        setShowTimeSlots(false)
    }
    useEffect(() => {
        if(showDay === 'Friday'){

            setShowTime(6)
        }
    },[showDay])
    // 10192D
    // 110609
    // console.log('showTime',showTime)
    // console.log('shoshowDaywTime',showDay)
    // console.log('ticketModal', openTicketModal)

  return (
    <>
    <div style={{opacity}} ref={target} id='home' className='w-full h-full px-2 font-satoshi min-h-full'>
        <div className="container mx-auto py-20  ">
            {!show.hasOwnProperty('message') ?
            <>
        {processing ?
            <div style={{scale}} className="px-4 flex justify-center items-center flex-col">
                <Puff
                height="80"
                width="80"
                radius={1}
                color="white"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                />
                {/* text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03]
        text-[#F86F03] */}
            {/* <p href="#shows" className='px-6 py-2 text-left md:text-center text-[17px] md:text-[40px] animate-pulse text-amber-700 font-semibold'>Loading ...</p> */}
        </div>
            :
            <>
        <p className='font-bold text-[36px] md:text-[60px] px-2 text-teal-400 py-2'>Upcoming show</p>
        <div style={{scale}} className="flex flex-col-reverse md:flex-col lg:flex-row px-4 py-2 md:py-6   gap-2 justify-between shadow-gray-600  drop-shadow-2xl shadow-2xl relative h-full">

            <div className="flex flex-col justify-start md:justify-center w-full lg:w-[40%]  gap-2">
            
                <p className=' text-[36px] md:text-[60px] text-white  font-bold'>
                    {show?.name}</p>
                <div className="flex flex-row gap-4">
                    {/* <p className='text-amber-700 capitalize'>{show?.day}</p> */}
                    <BiCalendarEvent className='text-white' size={30} />
                    <button onClick={() => setShowDays(!showDays)} className='flex flex-row justify-between gap-2 px-2 py-1 items-center bg-white rounded-md  relative'>{showDay !== ''?showDay:'Select Day'}
                        <HiChevronDown size={20}/>
                        {showDays &&
                        <div className="absolute -bottom-32 right-1 w-full flex flex-col gap-2 bg-white rounded-md py-1">
                            <p onClick={() => handleSelectDay('Friday')} className='hover:bg-gray-100 hover:text-black p-1 cursor-pointer rounded-sm bg-white text-black'>Friday</p>
                            <p onClick={() => handleSelectDay('Saturday')} className='hover:bg-gray-100 hover:text-black p-1 cursor-pointer rounded-sm bg-white text-black'>Saturday</p>
                            <p onClick={() => handleSelectDay('Sunday')} className='hover:bg-gray-100 hover:text-black p-1 cursor-pointer rounded-sm bg-white text-black'>Sunday</p>

                        </div>
                            }
                        </button>
                    {/* <p className='text-amber-700 capitalize'>July 9, 2022</p> */}
                    <div className="">
                        <button onClick={() => setShowTimeSlots(!showTimeSlots)} className='flex flex-row justify-between gap-2 px-2 py-1 items-center bg-white rounded-md  relative'>{showTime === 3 ? '15:00 PM': showTime === 6 ? '18:30 PM':'Select time '}
                        <HiChevronDown size={20}/>
                        {showTimeSlots && showDay !== 'Friday' && showDay !== '' &&
                        <div className="absolute -bottom-20  right-1 w-full flex flex-col gap-2 bg-gray-600 rounded-md px-2 py-1">
                            <p onClick={() => handleSelectTimeSlot(3)} className='hover:bg-gray-100 hover:text-black p-1 cursor-pointer rounded-sm bg-white text-black'>15:00 PM</p>
                            <p onClick={() => handleSelectTimeSlot(6)} className='hover:bg-gray-100 hover:text-black p-1 cursor-pointer rounded-sm bg-white text-black'>18:30 PM</p>

                        </div>
                            }
                        </button>
                    </div>
                </div>
                <p className=' text-[24px] font-bold'>Buy Tickets</p>
                <div className="flex flex-col md:flex-row gap-3">
                    {/* {show?.tickets?.map((ticket) => (

                    <button
                    onClick={() => handleTicketClick('REGULAR', 1)}
                     className={`px-4 py-2 bg-[#30a5cf]  uppercase font-semibold rounded-md text-center text-[16px] shadow-md`}>Buy Ticket KES {showAmount}</button>
                    ))} */}
                   
                <button 
                 className={`px-4 py-2 bg-[#eff2f3]  uppercase font-semibold rounded-md text-center text-[16px] shadow-md`}
                onClick={seatMap}>Select specific seats</button>
                </div>
            </div>


           
            {isMobile ?
            <div className="">
                <img src={show?.image_url} className='w-full h-full rounded' alt="" />
            </div>
            :
            <div className="flex-1 w-full h-96">
              <img src={show?.image_url} className='w-full h-full aspect-video' alt="" />
            </div>
            
        //     <div className="h-full md:h-[500px] relative bg-[length:1000px_600px] rounded flex-1 bg-no-repeat bg-center w-full" style={{backgroundImage: `url(${show?.image_url})`, width:'100%'}}>
        //     <div className="absolute inset-0 bg-black bg-opacity-50 text-white hover:bg-opacity-80 transition duration-300">
        //   <div className="flex flex-col justify-end px-1 py-1 h-full">

        //   </div>
        // </div>
        //     {/* <img src={show?.image_url} alt="" className='h-full w-full object-left-bottom object-cover' /> */}
        //     </div>
            }
            {/* <a href='#shows' className="child animate-bounce  flex flex-row justify-center items-center bg-amber-700 rounded-full p-4">
                
                    <HiArrowLongDown size={25}/>
                
            </a> */}
        </div>
        </>
        }
        </>

        : 
        <HomeCarousel images={images}/>
    //     <div className="flex text-white flex-col  px-4 h-full md:h-[400px] gap-4 justify-between shadow-gray-600  drop-shadow-2xl shadow-2xl">
    //     <div className="px-4 py-4 md:py-10 w-full">
    //         <div className="h-full w-full">
    //             <img src={images[0]?.image_url} alt="" className='h-full w-full' />
    //         </div>

    //     </div>
    // </div>

        }
        {(showDay !== "" || showTime !== "") && show === "" && (
          <div className="">
            <p className='font-bold text-[20px] px-2 text-white py-2'>No show for {showDay}</p>
          </div>
        )}

        </div>
    </div>

    
    {openTicketModal !== '' &&
    <ModalLayout open={openTicketModal !== ''} setToggleModal={handleCloseTicketModal} title={`Buy Ticket`}>
        <TicketModal  ticket={openTicketModal} setToggleModal={handleCloseTicketModal} amt={show?.price} show={show?.id} ticket_time={showTime} ticket_day={showDay}/>
    </ModalLayout>
    }
    </>
  )
}

export default Landing